$white:#ffffff;
$header_color:#181715;
$font_color:#3f3c38;
$font_color2:#cacaca;
$font_color3:#565656;
$theme_color1:#f5f5f5;
$theme_color2:#171717;
//$accent_color:#a89e6f;
$accent_color:#3bc8f5;
$border_color:#e7e7e7;
$main_font:'TT Commons';
$footer_font_color:#ffffff;
$black:#000000;

$special_color: #A89D6E;

@import "panel/fontawesome/scss/fontawesome";
@import "panel/fontawesome/scss/solid";
@import "panel/fontawesome/scss/brands";
@import "panel/fontawesome/scss/regular";

@mixin fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin anim {
    transition: 0.3s all ease;
}

@mixin bg_pos {
    background-repeat: no-repeat;
    background-position: center;
}

.alt_image{
    @include fill;
    opacity: 0;
}

body{
    font-family: $main_font;
    font-size: 20px;
    line-height: 22px;
    color: $font_color;
    &.scroll_block{
        overflow: hidden;
    }
    @media only screen and (max-width: 1500px) {
        font-size: 18px;
        line-height: 20px;
    }
    @media only screen and (max-width: 1200px) {
        font-size: 16px;
        line-height: 18px;
    }
    h1,h2,h3,h4,h5,h6,ul,ol,a,p{
        font-weight: 400;
        margin: 0;
    }
    ul{
        list-style: none;
        padding: 0;
    }
    strong{
        font-weight: 600;
    }
}

.vertical_align{
    @include fill;
    > div{
        display: table;
        height: 100%;
        width: 100%;
        > div{
            display: table-cell;
            vertical-align: middle;
            height: 100%;
        }
    }
}

.more_btn{
    display: inline-block;
    font-size: 16px;
    //padding-left: 100px;
    background-position: left center;
    background-repeat: no-repeat;
    //background-image: url('/img/btn_shape.png');
    color: $font_color;
    font-weight: 600;
    text-decoration: underline;
    padding-top: 3px;
    @media only screen and (max-width: 991px) {
        padding-left: 0;
        padding-bottom: 20px;
        background-position: center bottom;
        display: block;
    }
}

.alert_info_close{
    cursor: pointer;
}

.default_font{
    font-size: 18px;
    line-height: 35px;
    color: $font_color;
    h1{
        font-size: 62px;
        line-height: 72px;
        @media only screen and (max-width: 1500px) {
            font-size: 30px;
            line-height: 40px;
        }
    }
    h2{
        font-size: 30px;
        line-height: 40px;
        @media only screen and (max-width: 1500px) {
            font-size: 25px;
            line-height: 32px;
        }
    }
    h3{
        font-size: 25px;
        line-height: 32px;
        @media only screen and (max-width: 1500px) {
            font-size: 22px;
            line-height: 32px;
        }
    }
    h4{
        font-size: 22px;
        line-height: 32px;
        @media only screen and (max-width: 1500px) {
            font-size: 20px;
            line-height:30px;
        }
    }
    h5{
        font-size: 20px;
        line-height:30px;
        @media only screen and (max-width: 1500px) {
            font-size: 18px;
            line-height: 28px;
        }
    }
    h6{
        font-size: 18px;
        line-height: 28px;
        @media only screen and (max-width: 1500px) {
            font-size: 16px;
            line-height: 26px;
        }
    }
    ul{
        padding-left: 18px;
        list-style: disc;
        > li{
            padding-left: 18px;
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    ol{
       > li{
           padding-left: 18px;
           margin-bottom: 15px;
           &:last-child{
               margin-bottom: 0;
           }
       }
    }
    a{
        color: $font_color;
       font-weight: 600;
       text-decoration: none;
       border-bottom: 1px solid $font_color;
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin-bottom: 30px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 10px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    table{
        width: 100%;
        border: 1px solid #cedce1;
        th, td{
            border-right: 1px solid #cedce1;
            padding: 25px 15px;
            font-weight: 600;
            text-align: center;
            p{
                font-weight: inherit;
            }
            &:last-child{
                border-radius: none;
            }
        }
        thead{
            background: $accent_color;
            color: $black;
            font-size: 18px;
            line-height: 28px;
            text-align: center;
        }
        tbody{
            font-size: 16px;
            line-height: 26px;
            color: #565656;
            tr{
                &:nth-child(odd){
                    background: $white;
                }
                &:nth-child(even){
                    background:#e8f2f6;
                }
            }
        }
    }
}

div.top_logotypes{
    .logotypes{
        padding: 11px 0;
        @media only screen and (max-width: 1200px) {
            text-align: center;
            .text-right{
                text-align: center;
            }
        }
        @media only screen and (max-width: 991px) {
            padding: 11px 0 0 0;
        }
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                margin-right: 45px;

                @media only screen and (max-width: 991px) {
                    text-align: center;
                    margin-right: 0;
                    width: 33.33%;
                    margin-bottom: 15px;
                    img{
                        height: 20px;
                        width: auto;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}

section.alert_box{
    border-bottom:1px solid #e7e7e7; background-color:#f5f5f5;
}

.page{
    overflow: hidden;
    &.subpage{
        .header{
            padding-top: 0;
            top: 30px;
            @media only screen and (max-width: 991px) {
                top:0;
            }
        }
    }
    @media only screen and (max-width: 991px) {
        .text-right, .text-left{
            text-align: center;
        }
    }
    &.contrast_on{
        .last_events {
            .calendar {
                > header{
                    color: yellow !important;
                }
            }
        }
        footer.footer {
            div.footer_top {
                div.newsletter {
                    input.n_submit {
                        background: $black !important;
                        border: 1px solid yellow !important;

                    }
                }
            }
        }
        h1,h2,h3,h4,h5,h6,p,div,li,td,thead, span{
            color: yellow !important;
            background: $black !important;
        }
        header.header div.menu_box nav.menu > ul > li > div > a:after{
            background: yellow !important;
        }
        section.councils div.councils_list > ul > li div.short_desc header:before{
            display: none !important;
        }
        th,td,table{
            border: none !important;
        }
        div, section{
            border: none !important;
        }
        a{
            background: transparent !important;
            color: red !important;
        }
        section,footer{
            background: $black;
        }
        .bg_dotts, .border{
            opacity: 0;
        }
        .last_news, .last_events {
            .arrs, .default_font{
                background: transparent;
            }
        }
        header.section_header{
            &:after{
                opacity: 0;
            }
        }
        .header{
            nav.menu{
                ul{
                    background: $black;
                }
            }
        }
        .last_events{
            .calendar{
                > header{
                    background: $black;
                }
            }
        }
        header.header {
            a{
                background: $black !important;
            }
            div.menu_box {
                .text-right {
                    > ul {
                        > li{
                            &:before{
                                background: transparent;
                            }
                            &.font_size{
                                > ul{
                                    >li{
                                        &:after{
                                            content: '' !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .gallery, .small_galery{
            display: none;
        }
        .search_window{
            background: $black;
        }
        section.exhibition {
            .exhibition_btn{
                &:after{
                    border-top: 2px solid $black;
                }
            }
        }
        .border_header{
            border-bottom: none;
        }
        .downloads {
            ul{
                li{
                border: none !important;
                }
            }
        }
        .contact_box{
            li{
                border: none !important;
            }
        }
        .valign{
            img{
                display: none;
            }
        }
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $black !important;
          }
          ::-moz-placeholder { /* Firefox 19+ */
            color: $black !important;
          }
          :-ms-input-placeholder { /* IE 10+ */
            color: $black !important;
          }
          :-moz-placeholder { /* Firefox 18- */
            color: $black !important;
          }
          input{
              border: none !important;
          }
          input[type=text]{
              background: $white !important;
          }
          input[type=submit]{
              border: 1px solid yellow;
              background: black !important;
              color: yellow !important;
          }
    }


    &.letter_spacing {
        .content {
            * {
                letter-spacing: 3px;
            }
        }
    }

    &.par_spacing {
        .content {
            p {
                padding-bottom: 40px;
            }
        }
    }

    &.line_spacing {
        .content {
            * {
                line-height: 250%;
            }
        }
    }

    &.words_spacing {
        .content {
            * {
                word-spacing: 15px;
            }
        }
    }

    .word_spec_class {
        &.active {
            color: #3bc8f5;
        }
    }
}

header.header{
    width: 100%;
    position: absolute;
    top: 57px;
    left: 0;
    z-index: 11;
    padding-top: 57px;
    @include anim;
    &.opened{
        background: $black;
        overflow-y: scroll;
        padding-bottom: 30px;
    }
    @media only screen and (max-width: 991px) {
        padding-top: 0;
        top: 42px;
    }
    div{
        &.logo_box{
            position: relative;
            @media only screen and (max-width: 991px) {
                padding-top: 15px;
                div.rwd_btn{
                    width:30px;
                    position: absolute;
                    top: 17px;
                    right: 0;
                    z-index: 99;
                    > div{
                        width: 100%;
                        height: 3px;
                        margin-bottom: 3px;
                        background: $white;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
            img{
                @include anim;
                max-width: 100%;
                height: auto;
                @media only screen and (max-width: 1500px) {
                    max-width: 60%;
                }
                @media only screen and (max-width: 991px) {
                    max-width: 40px;
                    height: auto;
                }
            }
        }
        &.menu_box{
            font-size: 23px;
            line-height: 23px;
            padding-top: 28px;
            position: relative;
            @media only screen and (max-width: 1500px) {
                font-size: 18px;
                line-height: 18px;
                img{
                    width: 20px;
                    height: auto;
                }
            }
            @media only screen and (max-width: 1200px) {
                font-size: 16px;
                line-height: 16px;
                img{
                    width: 18px;
                    height: auto;
                }
            }
            @media only screen and (max-width: 991px) {
                display: none;
                > div.row{
                    display: flex;
                    flex-flow: column;
                    > div{
                        align-items: stretch;
                        &.col-md-7{
                            order: 2;
                        }
                        &.col-md-5{
                            order: 1;
                        }
                    }
                }
            }
            nav.menu{
                padding-top: 5px;
                @media only screen and (max-width: 991px) {
                    ul{
                        li{
                            margin-bottom: 5px;
                        }
                    }
                    a{
                        text-transform: uppercase;
                        display: block;
                        height: 25px;
                        line-height: 25px !important;
                    }
                }
                > ul{
                    > li{
                        display: inline-block;
                        vertical-align: top;
                        margin-right: 45px;
                        //position: relative;
                        @media only screen and (max-width: 1500px) {
                            margin-right: 25px;
                        }
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            margin-right: 0;
                            text-align: center;
                            position: relative;
                        }
                        &.active{
                            &:hover > ul{
                                opacity: 1;
                                visibility: visible;
                            }
                            > div {
                                a{
                                    text-decoration: underline;
                                    &:after{
                                        width: 100%;
                                    }
                                }
                            }

                        }
                        &:last-child{
                            margin-right: 0;
                        }
                        > div{
                            > a, p{
                                cursor: pointer;
                                text-decoration: none;
                                color: $white;
                                font-weight: 600;
                                position: relative;
                                padding-bottom: 35px;
                                display: block;
                                background: rgba(0, 0, 0, 0.01);
                                cursor: pointer;
                                @media only screen and (max-width: 991px) {
                                    padding-bottom: 0;
                                }
                                &:after{
                                    content: '';
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    height: 3px;
                                    width: 0;
                                    background: $accent_color;
                                    @include anim;
                                    @media only screen and (max-width: 991px) {
                                        display: none;
                                    }
                                }
                            }
                        }
                        > ul{
                            //width: 100% !important;
                            visibility: hidden;
                            opacity: 0;
                            @include anim;
                            position: absolute;
                            top: 100%;
                            //left: 0;
                            background: $theme_color1;
                            z-index: 3;
                            display: block;
                            padding:40px;
                            @media only screen and (max-width: 991px) {
                                width: 100% !important;
                                position: static;
                                top: auto;
                                visibility: visible;
                                opacity: 1;
                                padding: 10px 0 0 0;
                                background: transparent;
                                left: 0 !important;
                                display: none;
                                transition: none;
                            }
                            > li{
                                display: inline-block;
                                vertical-align: top;
                                width: 20%;
                                text-align: center;
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                    text-align: center;
                                    position: relative;
                                }
                                &:hover{
                                    a{
                                        background-color: $white;
                                        -webkit-box-shadow: 5px 9px 46px rgba(172, 185, 191, 0.4);
                                        -moz-box-shadow: 5px 9px 46px rgba(172, 185, 191, 0.4);
                                        box-shadow: 5px 9px 46px rgba(172, 185, 191, 0.4);
                                    }
                                    @media only screen and (max-width: 991px) {
                                        a{
                                            background-color: transparent;
                                            box-shadow: none;
                                        }
                                    }
                                }
                                &.on {
                                    border-bottom:3px solid $accent_color;
                                }
                                > div{
                                    width: 100%;
                                    a{
                                        @include anim;
                                        width: 100%;
                                        background-position: center top 20px;
                                        background-repeat: no-repeat;
                                        color: $font_color;
                                        display: inline-block;
                                        padding:95px 0 25px 0;
                                        text-transform: uppercase;
                                        font-weight: 600;
                                        @media only screen and (max-width: 1500px) {
                                            font-size: 18px;
                                            line-height: 18px;
                                        }
                                        @media only screen and (max-width: 1500px) {
                                            font-size: 16px;
                                            line-height: 16px;
                                        }
                                        @media only screen and (max-width: 991px) {
                                            background-image:none !important;
                                            padding: 0;
                                            color: $white;
                                        }
                                    }
                                }
                            }
                        }
                        @media only screen and (max-width: 991px) {
                            .menu_more{
                                width: 25px;
                                height: 25px;
                                background: $accent_color;
                                position: absolute;
                                top: 0;
                                right: 0;
                                @include anim;
                                &.open{
                                    transform: rotate(90deg);
                                }
                                &:after{
                                    content: '>';
                                    color: $white;
                                    line-height: 25px;
                                }
                            }
                        }
                    }
                }
            }
            .text-right{
                > ul{
                    display: table;
                    height: 32px;
                    margin-left: auto;
                    > li{
                        height: 100%;
                        display: table-cell;
                        vertical-align: middle;
                        padding: 0 15px 0 30px;
                        position: relative;
                        cursor: pointer;
                        background: rgba(0, 0, 0, 0.01);
                        p,img{
                            @include anim;
                        }
                        @media only screen and (max-width: 1500px) {
                            padding: 0 15px;
                        }
                        @media only screen and (max-width: 991px) {
                            display: none;
                            &:first-child, &:last-child{
                                display: inline-block;
                                padding: 0 0 0 15px;
                            }
                        }
                        &:before{
                            background: $font_color2;
                            opacity: 0.3;
                            height: 2px;
                            width: 20px;
                            position: absolute;
                            top: 40%;
                            left: 0;
                            content: '';
                            @media only screen and (max-width: 1500px) {
                               width:10px;
                            }
                            @media only screen and (max-width: 991px) {
                                display: none;
                            }
                        }
                        &.font_size{
                            > ul{
                                > li{
                                    padding-right: 13px;
                                    margin-right: 10px;
                                    position: relative;
                                    &:last-child{
                                        padding-right: 0;
                                        margin-right: 0;
                                        &:after{
                                            display: none;
                                        }
                                    }
                                    &:after{
                                        content: '|';
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                    }
                                }
                            }
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                        ul{
                            li{
                                display: inline-block;
                               // vertical-align: middle;
                                color: $font_color2;
                                a{
                                    text-decoration: none;
                                    color: inherit;
                                }

                                &.active {
                                    a {
                                        color: $accent_color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes noise {
    0%, 100% {
        background-position: 0 0;
    }

    10% {
        background-position: -5% -10%;
    }

    20% {
        background-position: -15% 5%;
    }

    30% {
        background-position: 7% -25%;
    }

    40% {
        background-position: 20% 25%;
    }

    50% {
        background-position: -25% 10%;
    }

    60% {
        background-position: 15% 5%;
    }

    70% {
        background-position: 0% 15%;
    }

    80% {
        background-position: 25% 35%;
    }

    90% {
        background-position: -10% 10%;
    }
}

@keyframes noise2 {
    0% {
        background-position: 0 0;

    }
    30% {
        background-position: 0 -2px;
    }
    80% {
        background-position: 0 0;

    }
    90% {
        background-position: 0 0;

    }
    99%{
        background-position: 0 -2px;
        filter: sepia();
        opacity: 0.9;
    }
    99.1% {
        filter: sepia();
        opacity: 0.5;
    }
    100%{
        background-position: 0 -2px;

    }
}

section.slider{
    background-color: #0a0a0a;
    position: relative;
    width: 100%;
    overflow: hidden;
    height: calc(100vh - 57px);
    > ul{
        @include fill;
        > li{
            @include fill;
            &.active{
                > div{
                    &.slider_content{
                        left:0%;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            > div{
                &.slider_image{
                    @include fill;
                    @include bg_pos;
                    background-size: cover;
                   // animation: 0.8s infinite  noise2;
                    div.cover{
                        @include fill;
                        background: rgba(10,10,10,0.5);
                    }
                    /*&:before{
                        opacity: 0.3;
                        animation: 0.09s infinite noise;
                        content: '';
                        @include fill;
                        background: url('/img/grain.jpg');
                        background-size: cover;

                    }*/
                }
                &.slider_content{
                    width: 100%;
                    left: 100%;
                    position: absolute;
                    bottom: 0%;
                    font-size: 22px;
                    line-height: 32px;
                    color: $font_color2;
                    //padding-top: 7vh;
                    transition: 1s ease-in-out;
                    opacity: 0;
                    visibility: hidden;
                    height: 100%;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;

                    -ms-flex-align: center;
                    -webkit-align-items: center;
                    -webkit-box-align: center;

                    align-items: center;

                    //@media only screen and (max-width: 1500px) {
                    //    padding-top: 7vw;
                    //}
                    @media only screen and (max-width: 991px) {
                        text-align: center;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: flex;

                        -ms-flex-align: center;
                        -webkit-align-items: center;
                        -webkit-box-align: center;

                        align-items: center;
                        .container{
                            margin-bottom: 30%;
                        }
                    }
                    .tags{
                        margin-bottom: 50px;
                        @media only screen and (max-width: 1500px) {
                            margin-bottom: 30px;
                        }
                        @media only screen and (max-width: 991px) {
                            display: none;
                        }
                        ul{
                            li{
                                display: inline-block;
                                vertical-align: top;
                                padding-right: 50px;
                                margin-right: 20px;
                                position: relative;
                                &:after{
                                    background: $font_color2;
                                    opacity: 0.3;
                                    height: 2px;
                                    width: 20px;
                                    position: absolute;
                                    top: 40%;
                                    right: 0;
                                    content: '';
                                }
                                &:last-child{
                                    padding-right: 0;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    h1{
                        font-size: 100px;
                        line-height: 101px;
                        color: $white;
                        position: relative;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        padding-top: 15px;
                        @media only screen and (max-width: 1500px) {
                            font-size: 60px;
                            line-height: 61px;
                        }
                        @media only screen and (max-width: 991px) {
                            font-size: 40px;
                            line-height: 41px;
                        }
                    }
                }
            }

            > a {
                @include fill;
            }
        }
    }
    .slider_nav{
        position: absolute;
        left: 0;
        width: 100%;
        height:0px;

        top: 20%;
        @media only screen and (max-width: 991px) {
            top: auto;
            bottom: 0;
        }

        .container {
            height:0px;

            .row {
                height:0;
            }
        }

        .inner_slider{
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -ms-flex-align: center;
            -webkit-align-items: center;
            -webkit-box-align: center;
            align-items: center;

            height: calc(100vh - (57px + 227px));
            @media only screen and (max-width: 991px) {
                display: block;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 10px;
                height: auto !important;;
            }
            > div{
                display: inline-block;
                vertical-align: middle;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                &.slider_dots{
                    @media only screen and (max-width: 991px) {
                        text-align: center;
                    }
                    ul{
                        li{
                            width: 52px;
                            height: 0;
                            padding-bottom: 27px;
                            @include bg_pos;
                            background-image: url('/img/slider_dot.png');
                            background-size: contain;
                            cursor: pointer;
                            margin-bottom: 30px;
                            @include anim;
                            @media only screen and (max-width: 1660px) {
                                margin-bottom: 15px;
                                width: 30px;
                                padding-bottom: 20px;
                            }
                            @media only screen and (max-width: 991px) {
                                text-align: center;
                                display: inline-block;
                                vertical-align: top;
                                margin-right: 10px;
                                margin-bottom: 0;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                            &.on{
                                background-image: url('/img/slider_dot_a.png');
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                &.slider_btns{
                    width: calc(100% - 52px);
                    .movie_btn{
                        width: 60px;
                        height: 0;
                        padding-bottom: 56px;
                        margin: 0 auto;
                        border: 2px solid #8c8c8c;
                        border-radius: 50%;
                        @include bg_pos;

                        background-image: url('/img/pause.png');



                        cursor: pointer;
                        @media only screen and (max-width: 1200px) {
                            width: 40px;
                            padding-bottom: 36px;
                            background-size: 15px;
                        }
                        @media only screen and (max-width: 991px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .social{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 40px;
        text-align: right;
        @media only screen and (max-width: 991px) {
            display: none;
        }
        ul{
            li{
                display: inline-block;
                vertical-align: top;
                margin-right: 20px;
                padding-left: 30px;
                position: relative;
                &:before{
                    background: #cacaca;
                    opacity: 0.3;
                    height: 2px;
                    width: 20px;
                    position: absolute;
                    top: 40%;
                    left: 0;
                    content: '';
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}

section.fast_links{
    div.table_box{
        border-bottom: 1px solid $border_color;
        width: 100%;
        display: table;
        @media only screen and (max-width: 991px) {
           display: block;
           margin: 0;
        }
        > div{
            float: none;
            display: table-cell;
            vertical-align: top;
            border-right: 1px solid $border_color;
            @include anim;
            @media only screen and (max-width: 991px) {
                display: block;
                border-right: none;
            }
        &:hover{
            transform: scale(1.1);
            background: $border_color;
            @media only screen and (max-width: 991px) {
                transform: scale(1);
            }
            a{
                text-decoration: none;
            }
        }

            &:last-child{
                border-right:none;
            }
        }
    }
    article{
        padding: 37px;
        @media only screen and (max-width: 1500px) {
            padding: 30px;
        }
        div{
            &.icon{
                width: 64px;
                height: 0;
                padding-bottom: 64px;
                @include bg_pos;
                background-size: contain;
                margin: 0 auto 37px auto;
            }
        }

    }
    .short_desc{
        text-align: center;
        color: $font_color;
        h2{
            color: $header_color;
            margin-bottom: 20px;
            font-size: 22px;
            @media only screen and (max-width: 1500px) {
                font-size: 20px;
            }
            @media only screen and (max-width: 1200px) {
                font-size: 18px;
            }
        }
        h3{
            font-size: 18px;
        }
    }
}

.section_header{
    margin: 50px 0;
    @media only screen and (max-width: 991px) {
        margin: 30px 0;
    }
    .years{
        > ul{
           > li{
                display: inline-block;
                vertical-align: middle;
                width: 50%;
                @media only screen and (max-width: 1200px) {
                    width: 100%;
                }
            }
        }
    }
}

.border_header{
    margin-bottom: 25px;
    padding-bottom: 17px;
    border-bottom: 1px solid $accent_color;
    h3{
        font-size: 21px;
        line-height: 31px;
    }
}

.desc_header{
    color: $header_color;
    margin-bottom: 30px;
    &.colored{
        h2{
            padding-top: 30px;
            color: $font_color;
        }
        div.date, div.tags{
            display: inline-block;
            vertical-align: middle;
            p{
                font-weight: inherit;
            }
        }
        div.date{
            color: #6c6c6c;
            font-size: 16px;
            font-weight: 600;
        }
        div.tags{
            ul{
                padding-left: 24px;
                li{
                    color: $header_color;
                    padding:7px 16px;
                    background: $white;
                    font-size: 13px;
                    line-height: 13px;
                    font-weight: 600;
                    margin-right: 24px;
                    text-transform: uppercase;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }

        }
    }
    h2{
        font-size: 25px;
        line-height: 35px;
    }
}

.last_news, .last_events{
    .section_header_left{
        > div{
            width: 50%;
            &:first-child{
                text-align: left;
            }
        }
    }
}

//.last_news, .last_events, .gallery, .news, .search_results{
    header.section_header{
        position: relative;
        @media only screen and (max-width: 991px) {
            //text-align: center;
        }
        &:after{
            content: '';
            border-top: 1px solid $border_color;
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        div{
            &.default_font, &.arrs{
                position: relative;
                z-index: 2;
            }
            &.section_header_left{
                div.default_font{
                    padding-left: 0;
                    //width: 50%;
                    white-space: nowrap;
                }

                .theme_color1_bg {
                    background-color:$theme_color1;
                }
                @media only screen and (max-width: 991px) {
                    text-align: center;
                }
                h2 {
                    //padding-right:20px;
                    @media only screen and (max-width: 991px) {
                        padding-right: 0;
                    }
                }
            }
            &.default_font{
                display: inline-block;
                vertical-align: middle;
                padding-left: 45px;
                @media only screen and (max-width: 991px) {
                    padding-left: 0;
                }
            }
            &.arrs{
                display: inline-block;
                vertical-align: middle;
                width: 50%;
                img{
                    cursor: pointer;
                }
                > div{
                    margin: 0 auto;
                    width: 100px;
                    display: table;
                    height: 45px;
                    @media only screen and (max-width: 991px) {
                        margin: 0 0 0 auto;
                    }
                    > div{
                        height: 100%;;
                        display: table-cell;
                        width: 50%;
                        text-align: center;
                        border-right: 1px solid $border_color;
                        &.arr_left{
                            vertical-align: top;
                        }
                        &.arr_right{
                            vertical-align: bottom;
                            border-right: none;
                        }
                    }

                }
            }
        }
    }
//}

.last_news{
    background: $theme_color1;
    padding-bottom: 100px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 50px;
    }
    @media only screen and (max-width: 991px) {
        padding-bottom: 30px;
    }
    &.other_news{
        background: #e8e8e8;
        header.section_header{
            &:after{
                border-top: 2px solid #d3d3d3;
                @media only screen and (max-width: 991px) {
                    display: none;
                }
            }
        }
        div{
            &.default_font{
                background: #e8e8e8;
            }
            &.arrs{
                background: transparent;
                > div{
                    background: #e8e8e8;

                    //margin: 0 0 0 0 !important;
                    > div{
                        &:first-child{
                            border-right: 1px solid #d3d3d3 !important;
                        }
                    }
                }
            }
        }
        .news_carousel{
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: top;
                    width: 48%;
                    margin-right: 4%;
                    position: relative;
                    z-index: 2;
                    @include anim;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                    &:after{
                        content: '';
                        top: -15px;
                        left: -15px;
                        right: -15px;
                        bottom:-15px;
                        background: $white;
                        position: absolute;
                        z-index: -1;
                        @include anim;
                        visibility: hidden;
                        opacity: 0;
                    }
                    &.no_image{
                        article{
                            > div{
                                &.news_image{
                                    display: none;
                                }
                                &.news_desc{
                                    width: 100%;
                                    padding-left: 0;
                                    padding-bottom: 40px;
                                }
                            }
                        }
                    }
                    article{
                        > div{
                            display: inline-block;
                            vertical-align: top;
                            &.news_image{
                                width: 230px;
                                height: 0;
                                padding-bottom: 160px;
                                @include bg_pos;
                                background-size: cover;
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                }
                            }
                            &.news_desc{
                                width: calc(100% - 230px);
                                padding-left: 40px;
                                padding-top: 40px;
                                @media only screen and (max-width: 991px) {
                                    padding-left: 0;
                                    width: 100%;
                                }
                                > div{
                                    display: inline-block;
                                    vertical-align: top;
                                    &.date{
                                        width: 50px;
                                        color: #686867;
                                        background-repeat: no-repeat;
                                        background-image: url('/img/date_dots.png');
                                        background-position: right top;
                                        span{
                                            width: 100%;
                                            font-size: 37px;
                                            line-height: 37px;
                                            font-weight: 700;
                                            color:$font_color;
                                            display: block;
                                        }
                                    }
                                    &.desc{
                                        width: calc(100% - 50px);
                                        padding-left: 35px;
                                        color: $font_color;
                                        @media only screen and (max-width: 991px) {
                                            padding-left: 15px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    div{
        &.default_font, &.arrs{
            background: $theme_color1;
        }
    }
    .news_carousel{
        overflow: hidden;
        > ul{
            position: relative;
            white-space: nowrap;
            > li{
                display: inline-block;
                vertical-align: top;
                width: 30%;
                margin-right: 5%;
                white-space: normal;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-right: 0;
                }
                article{
                    > div{
                        display: inline-block;
                        vertical-align: top;
                        @media only screen and (max-width: 991px) {
                            display: block;
                        }
                        &.date{
                            width: 50px;
                            color: #686867;
                            background-repeat: no-repeat;
                            background-image: url('/img/date_dots.png');
                            background-position: right top;
                            @media only screen and (max-width: 991px) {
                                margin: 0 auto 20px auto;
                            }
                            span{
                                width: 100%;
                                font-size: 37px;
                                line-height: 37px;
                                font-weight: 700;
                                color:$font_color;
                                display: block;
                            }
                        }
                        &.desc{
                            width: calc(100% - 50px);
                            padding-left: 35px;
                            color: $font_color;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                text-align: center;
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.last_events{
    padding-bottom: 100px;
    @media only screen and (max-width: 991px) {
        padding-bottom: 30px;
    }
    a{
        text-decoration: none;
    }
    .calendar{
        margin-bottom: 30px;

        > header {
            background-color:#3bc8f5;

            color:$black;
            font-size:20px;
            padding:15px 0 12px 0;
            text-align: center;
        }

        @media only screen and (max-width: 991px) {
            margin-bottom: 0;
            margin-top: 30px;
        }
    }
    div{
        &.default_font, &.arrs{
            background: $white;
        }
    }
    .events_carousel{
        overflow: hidden;
        > ul{
            position: relative;
            white-space: nowrap;
            > li{
                white-space: normal;
                width: 48%;
                margin-right: 3.5%;
                display: inline-block;
                vertical-align: top;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-right: 0;
                }
                div{
                    @media only screen and (max-width: 991px) {
                        .text-right{
                            margin-top: 15px;
                        }
                    }
                    &.image{
                        width: 100%;
                        height: 0;
                        padding-bottom: 68.5%;
                        @include bg_pos;
                        background-size: cover;
                        .title{
                            margin-top: 20px;
                            margin-left: 20px;
                            display: inline-block;
                            font-size: 16px;
                            line-height: 16px;
                            padding: 7px 18px;
                            font-weight: 600;
                            background: $accent_color;
                            color: $black
                        }
                    }
                    &.desc{
                        color: $font_color;
                        header.date{
                            padding: 20px 0;
                            position: relative;
                            &:after{
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 45%;
                                width: 100%;
                                border-top: 1px solid $border_color;
                            }
                            >  div{
                                display: inline-block;
                                vertical-align: top;
                                width: 50%;
                                font-size: 30px;
                                line-height: 30px;
                                color: #686867;
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                    text-align: center;
                                }
                                &:first-child{
                                    white-space: nowrap;
                                    @media only screen and (max-width: 991px) {
                                        white-space: normal;
                                    }
                                    p{
                                        padding-right: 20px;
                                        @media only screen and (max-width: 991px) {
                                            padding-right: 0;
                                        }
                                    }
                                }
                                &:last-child{
                                    p{
                                        padding-left: 20px;
                                        @media only screen and (max-width: 991px) {
                                            padding-left: 0;
                                        }
                                    }
                                }
                                p{
                                    font-weight: 700;
                                    position: relative;
                                    z-index: 2;
                                    background: $white;
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.show_all{
    width: 240px;
    height: 130px;
    display: table;
    @include bg_pos;
    > div{
        display: table-cell;
        vertical-align: middle;
        height: 100%;
        text-align: center;
        font-size: 27px;
        line-height: 31px;
        color: $accent_color;
        padding-left: 70%;
        p{
            padding-top: 65px;
            font-weight: 700;
            background-position: center top;
            background-repeat: no-repeat;
            background-image: url('/img/dot_arr.png');
            margin-bottom: 52px;
        }
    }
}

section.gallery{
    background: $theme_color2;
    header.section_header{
        &:after{
            content: '';
            border-top: 1px solid $border_color;
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            opacity: 0.2;
        }
    }
    div{
        &.default_font{
            background: $theme_color2;
        }
        &.text-right{
            padding-top: 19px;
        }
    }
    div.default_font{
        color: $white;
        a{
            color: inherit;
            border-bottom: 1px solid $white;
        }
    }
    div.galery_holder{
        position: relative;
        .arr_left, .arr_right{
            height: 100%;
            position: absolute;
            top: 0;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            cursor: pointer;

            -ms-flex-align: center;
            -webkit-align-items: center;
            -webkit-box-align: center;

            align-items: center;
            width: 30px;
        }
        .arr_left{
            left: -50px
        }
        .arr_right{
            right: -50px
        }
    }
    div.galery_carousel{
        overflow: hidden;
        position: relative;
        > ul{
            position: relative;

            white-space: nowrap;
            > li{
                display: inline-block;
                vertical-align: top;
                width: 100%;
                margin-right: 5%;
                  div.image{
                    width: 100%;
                    height: 0;
                    padding-bottom: 49%;
                    @include bg_pos;
                    background-size: cover;
                }
            }
        }

        .pause_cover{
            @include fill;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;

            -ms-flex-align: center;
            -webkit-align-items: center;
            -webkit-box-align: center;

            align-items: center;
            .movie_btn{
                width: 60px;
                height: 0;
                padding-bottom: 56px;
                margin: 0 auto;
                border: 2px solid $white;
                border-radius: 50%;
                @include bg_pos;
                background-image: url('/img/pause.png');
                cursor: pointer;
                &.active{
                    background-image: url('/img/play_icon1.png');
                }
            }
        }
    }
}

footer.footer{
    color: $footer_font_color;
    background: $theme_color2;
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    div{
        &.footer_top{
            padding: 70px 0 30px 0;
            @media only screen and (max-width: 991px) {
                padding: 30px 0;
            }
            div.newsletter{
                ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: $white;
                  }
                  ::-moz-placeholder { /* Firefox 19+ */
                    color: $white;
                  }
                  :-ms-input-placeholder { /* IE 10+ */
                    color: $white;
                  }
                  :-moz-placeholder { /* Firefox 18- */
                    color: $white;
                  }
                input{
                    line-height: 60px;
                    height: 60px;
                    border: none;
                    display: inline-block;
                    vertical-align: top;
                    @media only screen and (max-width: 991px) {
                        line-height: 40px;
                    }
                    &.n_input{
                        width: calc(100% - 230px);
                        padding: 0 30px;
                        color: $white;
                        font-size: 20px;
                        background: #393736;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            padding: 0 15px;
                            text-align: center;
                            height: 40px;
                            font-size: 15px;
                        }
                    }
                    &.n_submit{
                        background: $accent_color;
                        width: 230px;
                        //color: $white;
                        color: #393736;
                        font-size: 16px;
                        font-weight: 600;
                        text-decoration: underline;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            text-align: center;
                            height: 40px;
                            font-size: 13px;
                        }
                    }
                }
            }
            div.social{
                padding-top: 15px;
                text-align: right;
                @media only screen and (max-width: 991px) {
                    text-align: center;
                }
                ul{
                    li{
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 34px;
                        @media only screen and (max-width: 1500px) {
                            margin-right: 18px;
                        }
                        @media only screen and (max-width: 991px) {

                            &:first-child{
                                width: 100%;
                                margin-right: 0;
                                margin-bottom: 15px;
                            }
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }
            .bip{
                padding-top: 20px;
                .go_to_bip{
                    display: inline-block;
                    padding-right: 52px;
                    min-height: 31px;
                    line-height: 30px;
                    color: $footer_font_color;
                    background-repeat: no-repeat;
                    background-position: right top;
                    background-image: url('/img/bip.png');
                }
            }
        }
        &.footer_bottom{
            border-top: 1px solid #393736;
            padding: 50px 0;
            font-size: 15px;
            line-height: 26px;
            @media only screen and (max-width: 991px) {
                padding: 20px 0;
            }
           div.footer_menu_box{

            p{
                color: $white;
                text-transform: uppercase;
                font-weight: 700;
                margin-bottom: 15px;
                @media only screen and (max-width: 991px) {
                    margin-top: 15px;
                }
            }
            a{
                color: $footer_font_color;
            }
           }
        }
    }
    .footer_desc1{
        padding-top:20px;
        ul{
            li{
                display: inline-block;
                vertical-align: middle;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                img{
                    height: 31px;
                    width: auto;
                    max-width: auto;
                    margin-left: 20px;
                    @media only screen and (max-width: 991px) {
                        margin-left: 0;
                        margin-top: 15px;
                    }
                }

            }
        }
    }
    .footer_desc2{
        padding-top: 15px;
        ul{
            li{
                display: inline-block;
                vertical-align: middle;
                &:first-child{

                    width: 200px;
                    @media only screen and (max-width: 1500px) {
                        width: 160px;
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                }
                &:last-child{
                    width: calc(100% - 200px);
                    padding-left: 15px;
                    @media only screen and (max-width: 1500px) {
                        width: calc(100% - 160px);
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        padding-left: 0;
                        padding-top: 20px;
                    }
                }
                img{
                    max-width: 100%;
                    height: auto;
                    @media only screen and (max-width: 991px) {
                        max-width: 200px;
                    }
                }
            }
        }
    }
    .footer_rules{
        padding-bottom: 30px;
        ul{
            li{
                display: inline-block;
                vertical-align: top;
                margin-right: 15px;
                &:last-child{
                    margin-right: 0;
                }
                a{
                    font-size: 15px;
                    line-height: 15px;
                    text-transform: uppercase;
                    color: $footer_font_color;
                }
            }
        }
    }
}

section.top{
    position: relative;
    height: 380px;
    background-color: #0a0a0a;
    //overflow: hidden;
    @media only screen and (max-width: 1500px) {
        height: 300px;
    }
    @media only screen and (max-width: 991px) {
        height: 250px;
    }
    .top_form{
        >ul{
           > li{
                display: inline-block;
                vertical-align: middle;
                color: $font_color2;
                margin-right: 50px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .default_font{
        color: $white;
    }
    &.top_details{
        @media only screen and (max-width: 991px) {
            .nav_way{
                padding: 10px 0 !important;
            }
            .back_btn{
                margin-bottom: 10px;
            }
        }
        p{
            color: #a5a5a5;
            margin-bottom: 15px;
            font-size: 14px;
        }
        .default_font {
            h1{
                font-size: 43px;
                line-height: 44px;
                @media only screen and (max-width: 991px) {
                    font-size: 13px;
                    line-height: 23px;
                }
            }
        }
        .text-right{
            padding-top: 13px;
        }
        .back_btn{
            border: 1px solid $accent_color;
            color: $white;
            font-weight: 700;
            padding: 12px 30px;
            font-size: 13px;
            line-height: 13px;
            display: inline-block;
            @include anim;
            text-decoration: none;
            &:hover{
                background: $accent_color;
            }
        }
    }
    > div.image{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size:cover;
        opacity: 0.4;
        @media only screen and (max-width: 991px) {
            background-position: center;
        }
    }
    > .top_content{
        width: 100%;
        z-index: 2;
        position: absolute;
        left: 0;
        bottom: 0;
        header{
            text-align: center;
            color: $white;
        }
        .nav_way{
            padding: 30px 0;
            ul{
                li{
                    display: inline-block;
                    vertical-align: top;
                    &:hover{
                        div{
                            a{
                                color: $white;
                               text-decoration: underline;
                            }
                        }
                    }
                    &:last-child{
                        div{
                            a{
                                &:after{
                                    display: none;
                                }
                            }
                        }
                    }
                    div{
                        &.on{
                            a{
                                color: $white;
                               text-decoration: underline;
                            }
                        }
                        a{
                            text-decoration: none;
                            color: $font_color2;
                            font-size: 14px;
                            line-height: 14px;
                            @include anim;
                            &:after{
                                content: '';
                                width:10px;
                                border-top: 2px solid $font_color2;
                                opacity: 0.5;
                                display: inline-block;
                                vertical-align: middle;
                                margin: 0 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

section.news, .search_results{
    background: $theme_color1;
    @media only screen and (max-width: 1200px) {
        .section_header{
            text-align: center;
        }
    }
    div{
        &.default_font{
            padding-top: 5px;
        }
        &.default_font, &.text-center{
            position: relative;
            z-index: 2;
            background: $theme_color1;
        }
        &.news_list{
            ul.news_list_ul{
                li{
                    display: inline-block;
                    vertical-align: top;
                    width: 100%;
                    &.one_news {
                        width:48%;
                    }

                    margin-right: 4%;
                    white-space: normal;
                    margin-bottom: 4%;
                    position: relative;
                    z-index: 2;
                    @include anim;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 15px;
                    }
                    &:after{
                        content: '';
                        top: -15px;
                        left: -15px;
                        right: -15px;
                        bottom:-15px;
                        background: $white;
                        position: absolute;
                        z-index: -1;
                        @include anim;
                        visibility: hidden;
                        opacity: 0;
                    }
                    &:hover{
                        transform: scale(1.05);
                        @media only screen and (max-width: 991px) {
                            transform: scale(1);
                        }
                        &:after{
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                    &.no_image{
                        article{
                            > div{
                                &.news_image{
                                    display: none;
                                }
                                &.news_desc{
                                    width: 100%;
                                    padding-left: 0;
                                    padding-bottom: 40px;
                                }
                            }
                        }
                    }
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                    article{
                        > div{
                            display: inline-block;
                            vertical-align: top;
                            &.news_image{
                                width: 230px;
                                height: 0;
                                padding-bottom: 160px;
                                @include bg_pos;
                                background-size: cover;
                                @media only screen and (max-width: 1200px) {
                                    width: 100%;
                                    padding-bottom: 66%;
                                }
                            }
                            &.news_desc{
                                width: calc(100% - 230px);
                                padding-left: 40px;
                                padding-top: 40px;
                                @media only screen and (max-width: 1200px) {
                                    width: 100%;
                                    padding-left: 0;
                                }
                                @media only screen and (max-width: 991px) {
                                    padding-top: 15px;
                                }
                                > div{
                                    display: inline-block;
                                    vertical-align: top;
                                    &.date{
                                        width: 50px;
                                        color: #686867;
                                        background-repeat: no-repeat;
                                        background-image: url('/img/date_dots.png');
                                        background-position: right top;
                                        span{
                                            width: 100%;
                                            font-size: 37px;
                                            line-height: 37px;
                                            font-weight: 700;
                                            color:$accent_color;
                                            display: block;
                                        }
                                    }
                                    &.desc{
                                        width: calc(100% - 50px);
                                        padding-left: 35px;
                                        color: $font_color;
                                        @media only screen and (max-width: 991px) {
                                            padding-left: 15px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

section.history{
    background: $theme_color1;
    div.desc{
        padding: 60px 0;
        @media only screen and (max-width: 991px) {
            padding: 30px 0;
        }
    }
    .timeline{
        padding-top: 60px;
        padding-bottom: 60px;
        position: relative;
        &:after{
            position: absolute;
            top: 0;
            left: 50%;
            content: '';
            height: 100%;
            width: 2px;
            background-position: center top;
            background-repeat: repeat-y;
            background-image: url('/img/line.png');
        }
        div{
            &.row{
                margin-bottom: 30px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            .col-md-6{
                position: relative;
                z-index: 2;
                &:after{
                    content: '';
                    position: absolute;
                    top: -27px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    width: 16px;
                    height: 0;
                    padding-bottom: 16px;
                    background-image: url('/img/line_point.png');
                    @media only screen and (max-width: 991px) {
                        display: none;
                    }
                }
            }
            &.timeline_right{
                .col-md-6{
                    &:after{
                        left: -8px;
                        @media only screen and (max-width: 991px) {
                            left: 0;
                        }
                    }
                }
                .timeline_box{
                    margin-left: 30px;
                    @media only screen and (max-width: 991px) {
                        margin-left: 0;
                    }
                    .date{
                        left: -8px;
                        @media only screen and (max-width: 991px) {
                            left: 0;
                        }
                        &:after{
                            right: 100%;
                        }
                    }
                }
            }
            &.timeline_left{
                .col-md-6{
                    &:after{
                        right: -8px;
                        @media only screen and (max-width: 991px) {
                            right: 0;
                        }
                    }
                }
                .timeline_box{
                    margin-right: 30px;
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                    }
                    .image{
                        margin-left: auto;
                    }
                    .date{
                        right: -8px;
                        @media only screen and (max-width: 991px) {
                            right: 0;
                        }
                        &:after{
                            left: 100%;
                            -moz-transform: scale(-1, 1);
                            -webkit-transform: scale(-1, 1);
                            -o-transform: scale(-1, 1);
                            -ms-transform: scale(-1, 1);
                            transform: scale(-1, 1);
                        }
                    }
                }
            }
            &.timeline_box{
                position: relative;
                .timeline_desc{
                    background: $white;
                    padding: 48px 30px 30px 30px;
                    color: $font_color3;
                    -webkit-box-shadow: 5px 9px 46px rgba(172, 185, 191, 0.4);
                    -moz-box-shadow: 5px 9px 46px rgba(172, 185, 191, 0.4);
                    box-shadow: 5px 9px 46px rgba(172, 185, 191, 0.4);
                    @media only screen and (max-width: 991px) {
                        padding: 20px;
                    }
                }
                .date{
                    z-index: 2;
                    top: -20px;
                    position: absolute;
                    font-size: 21px;
                    line-height: 21px;
                    padding: 10px 27px;
                    display: inline-block;
                    background: $accent_color;
                    color: $black;
                    font-weight: 600;
                    @media only screen and (max-width: 991px) {
                        top: 0;
                        position: relative;
                        width: 100%;
                        text-align: center;
                    }
                    p{
                        font-weight: inherit;
                    }
                    &:after{
                        content: '';
                        width: 6px;
                        height: 13px;
                        position: absolute;
                        top: 0;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-image: url('/img/triangle.png');
                        @media only screen and (max-width: 991px) {
                            display: none;
                        }
                    }
                }
                header{
                    margin-bottom: 10px;
                    color: $header_color;
                    h3{
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
                .image{
                    width: 50%;
                    height: 0;
                    padding-bottom: 32%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    position: relative;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        padding-bottom: 200px;
                    }
                }
            }
        }
    }
}

section.contact{
    background:$theme_color1;
    div.search_box{
        padding: 60px 0;
        @media only screen and (max-width: 991px) {
            padding: 30px 0;
        }
        form{
            -webkit-box-shadow: 5px 9px 46px rgba(172, 185, 191, 0.4);
            -moz-box-shadow: 5px 9px 46px rgba(172, 185, 191, 0.4);
            box-shadow: 5px 9px 46px rgba(172, 185, 191, 0.4);
            input{
                display: inline-block;
                vertical-align: top;
                border: 1px solid $accent_color;
                height: 80px;
                @media only screen and (max-width: 991px) {
                    height: 40px;
                }
                &.s_input{
                    font-size: 18px;
                    padding: 0 75px;
                    width: calc(100% - 163px);
                    background-position: left 30px center;
                    background-repeat: no-repeat;
                    background-image: url('/img/search_icon.png');
                    background-color: $white;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        font-size: 14px;
                        padding: 0 40px;
                        background-position: left 15px center;
                        background-size: 15px;
                    }
                }
                &.s_submit{
                    width: 163px;
                    color: $white;
                    background: $accent_color;
                    font-size: 16px;
                    font-weight: 700;
                    text-transform: uppercase;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .place_box{
        padding: 40px;
        background: $white;
        margin-bottom: 40px;
        @media only screen and (max-width: 991px) {
            padding: 15px;
        }
        header{
            color: $font_color;
            margin-bottom: 30px;
        }
        .contact_box{
            padding-left: 10%;
            @media only screen and (max-width: 1200px) {
                padding-left: 0;
            }
            > ul{
                //border-top: 1px solid $accent_color;
                //border-left: 1px solid $accent_color;
                > li{
                    height: 200px;
                    padding: 30px;
                    border-top: 1px solid $accent_color;
                    border-right: 1px solid $accent_color;
                    border-bottom: 1px solid $accent_color;
                    display: inline-block;
                    vertical-align: top;
                    width: 33.33%;
                    &:nth-child(3n - 2){
                        border-left: 1px solid $accent_color;
                    }
                    @media only screen and (max-width: 991px) {
                        height: auto;
                        width: 100%;
                        padding: 15px;
                    }
                    div.default_font{
                        line-height: 24px;
                        margin-bottom: 15px;
                        span{
                            font-size: 14px;
                            line-height: 14px;
                            color: $font_color;
                        }
                        font-weight: 600;
                        p{
                            font-weight: inherit;
                        }
                    }
                }
                ul{
                    li{
                        font-size: 14px;
                        line-height: 30px;
                        min-height: 15px;
                        background-position: left center;
                        background-repeat: no-repeat;
                        padding-left: 25px;
                        color: $font_color;
                        &.phone{
                            background-image: url('/img/contact_icon1.png');
                        }
                        &.mail{
                            background-image: url('/img/contact_icon2.png');
                        }
                    }
                }
            }
        }
    }
}

.maps{
    background: $theme_color1;
    &.main_maps{
        padding-top: 60px;
        @media only screen and (max-width: 991px) {
            padding-top: 30px;
        }
    }
    .map_list{
        > ul{
            > li.map_box{
                margin-bottom: 60px;
                width: 100%;
                position: relative;
                padding-top: 74px;
                min-height: 360px;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 20px;
                    padding-top: 0;
                    text-align: center;
                }
                div{
                    &.map{
                        width: 70%;
                        height: 0;
                        padding-bottom: 25.5%;
                        position: absolute;
                        top: 0;
                        @media only screen and (max-width: 991px) {
                            position: relative;
                            width: 100%;
                            padding-bottom: 200px;
                        }
                        iframe{
                            border: 1px solid $white;
                            @include fill;
                        }
                    }
                    &.desc{
                        width: 35%;
                        padding: 50px;
                        background: $white;
                        position: relative;
                        z-index: 2;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            padding: 15px;
                        }
                        header{
                            color: $font_color;
                            margin-bottom: 30px;
                            @media only screen and (max-width: 991px) {
                                margin-bottom: 15px;
                            }
                        }
                        ul{
                            margin-bottom: 30px;
                            @media only screen and (max-width: 991px) {
                                margin-bottom: 0;
                            }
                            li{
                                font-size: 14px;
                                line-height: 30px;
                                min-height: 15px;
                                background-position: left center;
                                background-repeat: no-repeat;
                                padding-left: 30px;
                                color: $font_color;
                                font-weight: 700;
                                @media only screen and (max-width: 991px) {
                                    padding-left: 0;
                                    padding-top: 30px;
                                    background-position: center top;
                                }
                                p{
                                    font-weight: inherit;
                                }
                                &.phone{
                                    background-image: url('/img/contact_icon5.png');
                                }
                                &.adres{
                                    background-image: url('/img/contact_icon3.png');
                                }
                                &.open{
                                    background-image: url('/img/contact_icon4.png');
                                }
                            }
                        }
                    }
                }
                &.map_left{
                    div{
                        &.map{
                            left: 0;
                        }
                        &.desc{
                            margin-left: auto;
                        }
                    }
                }
                &.map_right{
                    div{
                        &.map{
                            right: 0;
                        }
                        &.desc{
                            margin-right: auto;
                        }
                    }
                }
            }
        }
    }
}

.classifieds{
    background: $theme_color1;
    .f_holder{
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        > div{
            &.date{
                width: 80px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 15px;
                }
            }
            &.tags{
                width: calc(100% - 80px);
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                ul{
                    @media only screen and (max-width: 991px) {
                        padding-left: 0;
                    }
                    li{
                        display: inline-block;
                        vertical-align: top;
                        margin-right: 15px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            margin-right: 0;
                            margin-bottom: 15px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    div{
        &.filters{
            padding: 28px 0;
            background: #dfdfdf;
            .f_box{
                font-size: 22px;
                line-height: 22px;
                color: $font_color;
                > div{
                    display: inline-block;
                    vertical-align: middle;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        text-align: center;
                    }
                    ul{
                        padding-left: 40px;
                        @media only screen and (max-width: 991px) {
                            padding-left: 0;
                            padding-top: 15px;
                        }
                        li{
                            display: inline-block;
                            vertical-align: top;
                            margin-right: 20px;
                            @media only screen and (max-width: 991px) {
                                margin-right: 0;
                                margin-bottom: 10px;
                                width: 100%;
                                text-align: center;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                            a{
                                cursor: pointer;
                                padding: 11px 22px;
                                display: block;
                                color: $font_color;
                                background: $white;
                                font-size: 13px;
                                line-height: 13px;
                                text-decoration: none;
                                @include anim;
                                font-weight: 700;
                                &:hover, &.active {
                                    background: $accent_color;
                                    color: $black;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.classfields_list{
            >ul{
              > li{
                    padding: 60px 0;
                    border-bottom: 1px solid #cecece;
                    @media only screen and (max-width: 991px) {
                        padding: 30px 0;
                    }
                    div.desc{
                        div.default_font{
                            color: $font_color3;
                        }
                    }
                }
            }
        }
    }
}

.partners{
    background: $theme_color1;
    padding: 60px 0;
    div.partners_list{
        ul{
            li{
                display: inline-block;
                vertical-align: top;
                width: 23%;
                height: 0;
                padding-bottom: 16.5%;
                border: #e0ddd0;
                background: $white;
                position: relative;
                margin-right: 2.6%;
                margin-bottom: 2.6%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    padding-bottom: 80%;
                    margin-right: 0;
                    margin-bottom: 15px;
                }
                a{
                    @include fill;
                    display: block;
                }
                &:nth-child(4n), &:last-child{
                    margin-right: 0;
                }
                &:hover{
                    div.desc{
                        visibility: visible;
                        opacity: 1;
                    }
                }
                div.image{
                    position: absolute;
                    top: 20%;
                    left: 15%;
                    width: 70%;
                    height: 60%;
                    @include bg_pos;
                    background-size: contain;
                }
                div.desc{
                    @include anim;
                    @include fill;
                    padding: 30px;
                    color: $white;
                    visibility: hidden;
                    opacity: 0;
                    background: rgba(0,0,0,0.7);
                    @media only screen and (max-width: 991px) {
                        text-align: center;
                    }
                    .more{
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        padding: 30px;
                        .more_btn{
                            color: $white;
                            text-decoration: none;

                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .side_left, .side_right{
        padding: 0 15px !important;
    }
}

section.exhibition{
    background: #e8e8e8;
    padding: 60px 0;
    @media only screen and (max-width: 991px) {
        padding: 15px 0 30px 0;
        > div.row{
            > div.col-md-6{
                display: flex;
                flex-flow: column;
                > div{
                    align-items: stretch;
                    &.image{
                        order: 2;
                        text-align: center;
                    }
                    &.exhibition_btn{
                        order: 1;
                        text-align: center;
                        margin: 0;
                        padding: 15px;
                        a{
                            background: none;
                            background: #e8e8e8;
                        }
                    }
                }
            }
        }
    }
    .left{
        .exhibition_btn{
            //padding-left: 30px;
            margin-top: 30px;
            a{
                background-position: right center;
                background-image: url('/img/dot_arr_right.png');
            }
        }
    }
    .right{
        .exhibition_btn{
            margin-bottom: 30px;
            //padding-left: 30px;
            a{
                background-position: left center;
                background-image: url('/img/dot_arr_left.png');
            }
        }
    }
    .exhibition_btn{
        position: relative;
        &:after{
            content: '';
            width: 100%;
            border-top: 2px solid #d1d1d1;
            position: absolute;
            top: 50%;
            left: 0;
        }
        a{
            position: relative;
            z-index: 2;
            background-color: #e8e8e8;
            padding-right: 40px;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            text-decoration: underline;
            padding-top: 10px;
            min-height: 40px;
            display: inline-block;
            color: $font_color;
            padding-left: 43px;
            background-repeat: no-repeat;
        }
    }
    div.image{
        width: 100%;
        height: 0;
        padding-bottom: 55%;
        @include bg_pos;
        background-size: cover;
        position: relative;
        div.cover{
            background: rgba(0,0,0,0.5);
            @include fill;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;

            -ms-flex-align: center;
            -webkit-align-items: center;
            -webkit-box-align: center;

            align-items: center;
        }
        h2{
            font-size: 5vw;
            line-height: 5vw;
            color: $white;
            font-weight: 700;
            @media only screen and (max-width: 991px) {
                display: block;
                text-align: center;
                width: 100%;
                font-size:10vw;
                line-height: 10vw;
            }
        }
    }
}

section.structure{
    padding:  60px 0;
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    div.structure_list{
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                width: 32%;
                position: relative;
                //height: 0;
               // padding-bottom: 20%;
                margin-right: 2%;
                margin-bottom: 2%;
                @media only screen and (max-width: 991px) {
                   // margin-right: 0;
                   // margin-bottom: 15px;
                   // width: 50%;
                   //// padding-bottom: 100%;
                    width: 100%;
                }
                &:hover{
                    a{
                        text-decoration: none;
                    }
                    article{
                        transform: scale(1.1);
                        color: $accent_color;
                    }
                }
                &:nth-child(3n), &:last-child{
                    margin-right: 0;
                }
                article{
                    @include bg_pos;
                    @include anim;
                    background-size: cover;
                    text-align: center;
                    font-size: 30px;
                    line-height: 33px;
                    color: $accent_color;
                    font-weight: 700;
                    background-color: rgba(0, 0, 0, 0.01);

                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;

                    -ms-flex-align: center;
                    -webkit-align-items: center;
                    -webkit-box-align: center;

                    align-items: center;
                    height: 282px;
                    > div{
                        width: 100%;
                        text-align: center;
                    }
                    @media only screen and (max-width: 1500px) {
                        font-size: 27px;
                        line-height: 30px;
                        height: 228px;
                    }
                    @media only screen and (max-width: 1200px) {
                        font-size: 24px;
                        line-height: 27px;
                        height: 188px;
                    }
                    @media only screen and (max-width: 991px) {
                        font-size: 20px;
                        line-height: 23px;
                        height: 282px;
                    }
                }
            }
        }
    }
}

.structure_details{
    background: $theme_color1;
    padding-bottom: 50px;
    @media only screen and (max-width: 991px) {
        padding-bottom: 15px;
    }
    .galery_carousel{
        padding: 30px 0;
        overflow: hidden;
        > ul{
            white-space: nowrap;
            position: relative;
            > li{
                display: inline-block;
                vertical-align: top;
                width: 32%;
                margin-right: 2%;
                .image{
                    width: 100%;
                    height: 0;
                    padding-bottom: 66%;
                    @include bg_pos;
                    background-size: cover;
                    position: relative;
                }
            }
        }
    }
    .contact_box{
        border: 1px solid $accent_color;
        background: $white;
        padding: 30px 0;
        > ul{
            padding: 0 10%;
            @media only screen and (max-width: 991px) {
                padding: 0 30px;
            }
            > li{
                display: inline-block;
                vertical-align: top;
                //width: 33.33%;
                width: 50%;
                margin-bottom: 30px;
                @media only screen and (max-width: 1200px) {
                    width: 100%;
                    margin-bottom: 20px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    text-align: center;
                }
                > div{
                    display: inline-block;
                    vertical-align: top;
                }
                .image{
                    @include bg_pos;
                    background-size: cover;
                    background-color: $theme_color1;
                    width: 100px;
                    height: 0;
                    padding-bottom: 100px;
                    @media only screen and (max-width: 991px) {
                        display: block;
                        margin: 0 auto 15px auto;
                    }
                }
                .c_box_desc{
                    width: calc(100% - 100px);
                    padding-left: 15px;
                    @media only screen and (max-width: 991px) {
                        padding-left: 0;
                        width: 100%;
                    }
                }
                div.default_font{
                    line-height: 24px;
                    margin-bottom: 15px;
                    span{
                        font-size: 14px;
                        line-height: 14px;
                        color: $font_color;
                    }
                    font-weight: 600;
                    p{
                        font-weight: inherit;
                    }
                }
            }
            ul{
                li{
                    font-size: 14px;
                    line-height: 30px;
                    min-height: 15px;
                    background-position: left top 7px;
                    background-repeat: no-repeat;
                    padding-left: 25px;
                    color: $font_color;
                    @media only screen and (max-width: 991px) {
                        padding-left: 0;
                        padding-top: 25px;
                        background-position: center top;
                    }
                    &.phone{
                        background-image: url('/img/contact_icon1.png');
                    }
                    &.mail{
                        background-image: url('/img/contact_icon2.png');
                    }
                }
            }
        }
    }
    .desc{
        padding: 50px 0;
    }
    .small_galery{
        padding-bottom: 35px;
        @media only screen and (max-width: 991px) {
            padding-bottom: 30px;
        }
        > ul{
            > li{
                width: 19%;
                margin-right: 1.2%;
                display: inline-block;
                vertical-align: top;
                margin-bottom: 15px;
                @include anim;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 15px;
                }
                &:hover{
                    transform: scale(1.1);
                }
                &:last-child,&:nth-child(5n){
                    margin-right: 0;
                }
                div.image{
                    @include bg_pos;
                    width: 100%;
                    height: 0;
                    padding-bottom: 66%;
                    background-size: cover;
                    position: relative;
                }
            }
        }
    }
}

.downloads{
    ul{
        padding-top: 15px;
        li{
            display: inline-block;
            vertical-align: top;
            width: 32%;
            margin-right: 2%;
            background: $white;
            border: 1px solid $accent_color;
            margin-bottom: 2%;
            -webkit-box-shadow: 5px 9px 46px rgba(172, 185, 191, 0.3);
            -moz-box-shadow: 5px 9px 46px rgba(172, 185, 191, 0.3);
            box-shadow: 5px 9px 46px rgba(172, 185, 191, 0.3);
            @media only screen and (max-width: 991px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 15px;
            }
            &:nth-child(3n), &:last-child{
                margin-right: 0;
            }
             div{
                > div{
                    display: inline-block;
                    vertical-align: top;
                    &.file{
                        width: calc(100% - 60px);
                        padding: 10px 20px 0 20px;
                        color: $font_color;
                        p{
                            padding-top: 12px;
                            padding-left: 40px;
                            height: 40px;
                            background-repeat: no-repeat;
                            background-position: left center;
                            background-image: url('/img/file_icon.png');
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        span{
                            color: $font_color2;
                        }
                    }
                    &.icon{
                        border-left: 1px solid $accent_color;
                        width: 60px;
                        height: 0;
                        padding-bottom: 60px;
                        background-image: url('/img/download_icon.png');
                        @include bg_pos;
                    }
                }
            }
        }
    }
}

section.councils{
    background: $theme_color1;
    padding-bottom: 50px;
    div{
        &.desc{
            padding: 50px 0;
            @media only screen and (max-width: 991px) {
                padding: 30px 0;
            }
        }
        &.councils_list{
            width: 60%;
            margin: 0 auto;
            @media only screen and (max-width: 1500px) {
                width: 80%;
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
                text-align: center;
            }
            > ul{
                > li{
                    width: 48%;
                    margin-right: 4%;
                    display: inline-block;
                    vertical-align: top;
                    overflow: hidden;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 15px;
                    }
                    /*&:hover{
                        div.short_desc{
                            header{
                                color: $accent_color;
                            }
                        }
                    }*/
                    div{
                        display: inline-block;
                        vertical-align: top;
                        &.image{
                            display: none;
                            width: 30%;
                            height: 0;
                            padding-bottom: 40%;
                            background-size: cover;
                            @include bg_pos;
                            position: relative;
                            z-index: 2;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                padding-bottom: 110%;
                            }
                        }
                        &.short_desc{
                            //padding-top: 50px;
                            //padding-left: 50px;
                            //width: calc(100% - 30%);
                            width: 100%;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                padding-left: 0;
                                padding-top: 15px;
                            }
                            header{
                                margin-bottom: 20px;
                                position: relative;
                                color: $header_color;
                                @include anim;
                                /*&:before{
                                    margin-right: 8px;
                                    content: '';
                                    height: 100%;
                                    width: 100%;
                                    position: absolute;
                                    top: 0;
                                    right: 100%;
                                    background-repeat: repeat-x;
                                    background-position: right top 4px;
                                    background-image: url('/img/btn_shape.png');
                                    @media only screen and (max-width: 991px) {
                                        display: none;
                                    }
                                }*/
                            }
                        }
                    }
                    &:nth-child(2n),&:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

section.news_details{
    background: $theme_color1;
    padding-bottom: 60px;
    div.main_img{
        margin: 60px 0;
        @include bg_pos;
        background-size: cover;
        width: 100%;
        height: 0;
        padding-bottom: 36%;
        @media only screen and (max-width: 991px) {
            margin: 30px 0;
        }
    }
}
.connected_interviews{
    .section_header{
        position: relative;
        &:after{
            content: '';
            border-top: 2px solid #d9d9d9;
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        .default_font{
            background: $theme_color1;
            position: relative;
            z-index: 2;
            display: inline-block;
            padding-right: 40px;
            padding-top: 10px;
            @media only screen and (max-width: 991px) {
                padding-right: 0;
            }
        }
        .text-right{
            .default_font{
                padding-left: 44px;
                padding-right: 0;
            }
            > ul{
                background: $theme_color1;
                position: relative;
                z-index: 2;
                display: inline-block;
                padding-left: 40px;
                @media only screen and (max-width: 991px) {
                    padding-left: 0;
                    width: 100%;
                }
                > li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 10px;
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                    }
                    &:first-child{
                        width: 170px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            margin-bottom: 15px;
                        }
                    }
                    &:last-child{
                        margin-right: 0;
                        width: 200px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .element_list{
        > ul{
            margin: 0 -15px;
            > li{
                margin-bottom: 30px;
                width: 20%;
                padding: 0 15px;
                display: inline-block;
                vertical-align: top;
                @media only screen and (max-width: 1500px) {
                    width: 25%;
                }
                @media only screen and (max-width: 1200px) {
                    width: 33.33%;
                }
                @media only screen and (max-width: 991px) {
                    width: 50%;
                }
                @media only screen and (max-width: 767px) {
                    width: 100%;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                &:hover{
                    a{
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .interview_box{
        background-color: $accent_color;
        padding: 26px 28px 24px 28px;
        background-repeat: no-repeat;
        background-position: right 30px top 30px;
        background-image: url("./../img/q_icon.png");
        @include anim;
        &:hover{
            transform: scale(0.97);
        }
        .image{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            @include bg_pos;
            background-size: cover;
            margin-bottom: 20px;
        }
        .desc{
            h3{
                font-size: 16px;
                line-height: 25px;
            }
            color: $header_color;
            strong{
                //display: block;
                color: $header_color;
            }
        }
    }
}
.events, .main_exhibition{
    background: $theme_color1;
    &.short_events{
        //padding-bottom: 60px;
        .section_header {
            margin-top: 0;
            margin-bottom: 0;
            .text-right{
                .default_font{
                    padding-left: 44px;
                    padding-right: 0;
                    @media only screen and (max-width: 991px) {
                        padding-left: 0;
                    }
                }
            }
            .text-left{
                .default_font{
                    padding-left: 44px;
                }
            }
        }
        @media only screen and (max-width: 991px) {
            .events_boxes{
                > div.row{
                    > div.col-md-4{
                        margin-bottom: 20px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .sites{
        margin-top: 30px;
    }
    .section_header{
        position: relative;
        &:after{
            content: '';
            border-top: 2px solid #d9d9d9;
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        .default_font{
            background: $theme_color1;
            position: relative;
            z-index: 2;
            display: inline-block;
            padding-right: 40px;
            padding-top: 10px;
            @media only screen and (max-width: 991px) {
                padding-right: 0;
            }
        }
        .text-right{
            > ul{
                background: $theme_color1;
                position: relative;
                z-index: 2;
                display: inline-block;
                padding-left: 40px;
                @media only screen and (max-width: 991px) {
                    padding-left: 0;
                    width: 100%;
                }
                > li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 10px;
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                    }
                    &:first-child{
                        width: 170px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            margin-bottom: 15px;
                        }
                    }
                    &:last-child{
                        margin-right: 0;
                        width: 200px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .rwd_choice{
        display: none;
        padding-bottom: 15px;
        @media only screen and (max-width: 991px) {
            display: block;
        }
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                height: 50px;
                line-height: 50px;
                font-size: 16px;
                width: 49%;
                margin-right: 2%;
                color: $white;
                background: $accent_color;
                text-align: center;
                cursor: pointer;
                font-weight: 600;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }

    .date_submit{
        margin-top: 40px;
        background:none;
        border: none;
        font-size: 16px;
        line-height: 21px;
        font-weight: 600;
        color: $black;
        padding: 5px;
        cursor: pointer;
        @media only screen and (max-width: 1200px) {
            margin-top: 15px;
            width: 100%;
            height: 50px;
            background: $accent_color;
            color: white;
            text-align: center;
            line-height: 50px;
        }
        @media only screen and (max-width: 991px) {
            margin-top: 0;
        }
    }
    .events_boxes{
        padding: 50px 0 0 0;
        article{
            margin-bottom:30px;
            @include anim;
            &:hover{
                transform: scale(1.05);
            }
        }
        @media only screen and (max-width: 991px) {
            padding: 30px 0 0 0;
        }
        > .row{
            margin-bottom: 30px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        div{
            &.image{
                width: 100%;
                height: 0;
                padding-bottom: 370px;
                @include bg_pos;
                background-size: cover;
                @media only screen and (max-width: 1500px) {
                    padding-bottom: 320px;
                }
                @media only screen and (max-width: 1200px) {
                    padding-bottom: 270px;
                }
                @media only screen and (max-width: 991px) {
                    padding-bottom: 100%;
                }
                .title{
                    margin-top: 20px;
                    margin-left: 20px;
                    display: inline-block;
                    font-size: 16px;
                    line-height: 16px;
                    padding: 7px 18px;
                    font-weight: 600;
                    background: $accent_color;
                    color: $black;
                }
            }
            &.desc{
                color: $font_color;
                .short_desc{
                    min-height: 44px;
                }
                header.date{
                    padding: 20px 0;
                    position: relative;
                    &:after{
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 45%;
                        width: 100%;
                        border-top: 1px solid $border_color;
                    }
                    >  div{
                        display: inline-block;
                        vertical-align: top;
                        width: 50%;
                        font-size: 30px;
                        line-height: 30px;
                        color: #686867;
                        &:first-child{
                            p{
                                padding-right: 20px;
                            }
                        }
                        &:last-child{
                            p{
                                padding-left: 20px;
                            }
                        }
                        p{
                            font-weight: 700;
                            position: relative;
                            z-index: 2;
                            background: $theme_color1;
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}

.event_details{
    background: $theme_color1;
    padding: 60px 0 0 0;
    @media only screen and (max-width: 991px) {
        padding-top: 30px;
    }
    div.split_view{
        width: 100%;
        > div{
            display: inline-block;
            vertical-align: top;
            width: 50%;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            &.left_side{
                > div{
                    margin-bottom: 15px;
                    &.img_holder1{
                        padding-right: 0;
                    }
                    &.img_holder2{
                        padding-right: 90px;
                        @media only screen and (max-width: 991px) {
                            padding-right: 0;
                        }
                        div.image{
                            padding-bottom: 75%;
                            @media only screen and (max-width: 991px) {
                                padding-bottom: 63%;
                            }
                        }
                    }
                    &.img_holder3{
                        div.image{
                            width: 49%;
                            padding-bottom: 67%;
                            display: inline-block;
                            vertical-align: top;
                            margin-right: 2%;
                            &:nth-child(2n){
                                margin-right: 0;
                            }
                        }
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                 div.image{
                    width: 100%;
                    height: 0;
                    padding-bottom: 63%;
                    margin-bottom: 20px;
                    @include bg_pos;
                    //background-size: cover;
                    background-size: contain;
                     position:relative;

                     a {
                         @include fill();
                     }

                }
            }
            &.right_side{
                padding-left: 60px;
                @media only screen and (max-width: 1200px) {
                    padding-left: 30px;
                }
                @media only screen and (max-width: 991px) {
                    padding-left: 0;
                }
                .section_header{
                    margin-top: 0;
                    &:after{
                        display: none;
                    }
                    div.default_font{
                        padding-left: 0;
                    }
                }

                .desc{
                    margin-bottom: 40px;
                }
                .share{
                    padding: 38px;
                    background: $white;
                    margin-bottom: 50px;
                    @media only screen and (max-width: 991px) {
                        padding: 15px;
                    }
                    > div{
                        display: inline-block;
                        vertical-align: middle;
                        width: 50%;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            text-align: center;
                            &:first-child{
                                margin-bottom: 15px;
                            }
                        }
                        p{
                            margin-bottom: 15px;
                        }
                        ul{
                            li{
                                display: inline-block;
                                vertical-align: top;
                                margin-right: 12px;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
                .downloads{
                    ul{
                        padding-top: 15px;
                        li{
                            display: inline-block;
                            vertical-align: top;
                            width: 100%;
                            background: $white;
                            border: 1px solid $accent_color;
                            margin-bottom: 2%;
                            -webkit-box-shadow: 5px 9px 46px rgba(172, 185, 191, 0.3);
                            -moz-box-shadow: 5px 9px 46px rgba(172, 185, 191, 0.3);
                            box-shadow: 5px 9px 46px rgba(172, 185, 191, 0.3);
                            &:nth-child(3n), &:last-child{
                                margin-right: 0;
                            }
                             div{
                                > div{
                                    display: inline-block;
                                    vertical-align: top;
                                    &.file{
                                        width: calc(100% - 60px);
                                        padding: 10px 20px 0 20px;
                                        color: $font_color;
                                        p{
                                            padding-top: 12px;
                                            padding-left: 40px;
                                            height: 40px;
                                            background-repeat: no-repeat;
                                            background-position: left center;
                                            background-image: url('/img/neutralna.svg');
                                            background-size:contain;
                                        }
                                        span{
                                            color: $font_color2;
                                        }
                                    }
                                    &.icon{
                                        border-left: 1px solid $accent_color;
                                        width: 60px;
                                        height: 0;
                                        padding-bottom: 60px;
                                        background-image: url('/img/download_icon.png');
                                        @include bg_pos;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.main_exhibition{
    background: $theme_color1;
    padding: 60px 0;
    .paggination_box{
        display: none !important;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    .section_header {
        margin-top: 0;
        margin-bottom: 0;
        position: relative;
        &:after{
            content: '';
            border-top: 2px solid #d9d9d9;
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        .text-right{
            .default_font{
                padding-left: 44px;
                padding-right: 0;
                background: $theme_color1;
                @media only screen and (max-width: 991px) {
                    padding-left: 0;
                }
            }
        }
        .text-left{
            .default_font{
                padding-left: 44px;
                background: $theme_color1;
            }
        }
    }
    > div.container{
        > div.row {
            margin-bottom: 30px;
            @media only screen and (max-width: 991px) {
                margin-bottom: 0;
                > div {
                    margin-bottom: 15px;
                }
            }

            &:last-child {
                //margin-bottom: 0;
            }

            > div {
                &.col-md-4 {
                    article {
                        padding-bottom: 100%;
                    }
                }

                &.col-md-8 {
                    article {
                        padding-bottom: 48.3%;
                        @media only screen and (max-width: 1500px) {
                            padding-bottom: 48%;
                        }
                        @media only screen and (max-width: 1200px) {
                            padding-bottom: 47.7%;
                        }
                        @media only screen and (max-width: 991px) {
                            padding-bottom: 100%;
                        }
                    }
                }

                &.col-md-6 {
                    article {
                        padding-bottom: 53.6%;
                        @media only screen and (max-width: 991px) {
                            padding-bottom: 100%;
                        }
                    }
                }
            }
        }
    }
    article{
        width: 100%;
        height: 0;
        margin-bottom:30px;
        @include bg_pos;
        background-size: cover;
        position: relative;
        @include anim;
        &:hover{
            transform: scale(1.05);
        }
        > div.cover{
            @include fill;
            background-position: left bottom;
            background-size: contain;
            background-repeat: repeat-x;
            background-image: url('/img/title_cover.png');
            div.exhibition_info{
                padding: 20px;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                color: $white;
                text-align: center;
                font-size: 20px;
                line-height: 30px;
                div.default_font{
                    color: $white;
                }
                header{
                    margin-bottom: 20px;
                    @include anim;
                    background: rgba(0, 0, 0, 0.01);
                }
                .place{
                    text-align: left;
                    p{
                        display: inline-block;
                        font-size: 16px;
                        line-height: 16px;
                        padding: 7px 14px;
                        font-weight: 600;
                        background: rgba(143,143,143,0.5);
                    }
                }
            }
        }
    }
}


.left_photos{
    .photo{
        position: relative;
        a{
            display: block;
            height: 100%;
            width: 100%;
        }
    }
}

section.exhibition_details, .interview_details{
    div.photo{
        width: 100%;
       // height: 16.3vw;
        height: 20vw;
        @include bg_pos;
        background-position: center top;
        background-size: contain;
        margin-bottom: 15px;
        @media only screen and (max-width: 991px) {
            height: 0;
            padding-bottom: 50%;
        }
    }
    .left_photos{
        padding-left: 50px;
        @media only screen and (max-width: 991px) {
            padding-left: 0;
        }
        &.relative{
            position: relative;
            right: -60px;
            @media only screen and (max-width: 991px) {
                right: 0;
            }
        }
    }
    .descc{
        @media only screen and (max-width: 1200px) {
            margin-bottom: 30px;
        }
    }
}

.events_boxes{
    header{
        .text-right{
            text-align: right;
        }
    }
    @media only screen and (max-width: 991px) {
        .row{
            margin-bottom: 0 !important;
            > div{
                margin-bottom: 15px;

            }
        }
    }
}

section.plan_visit{
    background: $theme_color1;
    padding: 50px 0;
    @media only screen and (max-width: 991px) {
        text-align: center;
        padding: 30px 0;
    }
    div{
        &.desc{
           padding-bottom: 50px;
           *{
               &:last-child{
                   margin-bottom: 0;
               }
           }
            @media only screen and (max-width: 991px) {
                padding: 30px 0;
            }
        }
        &.default_informations{
            .boxes{
                ul{
                    display: flex;
                    flex-flow: row;
                    margin: 0 -30px;
                    flex-wrap: wrap;
                    @media only screen and (max-width: 1200px) {
                        margin: 0 -15px;
                    }
                    li{
                        width: calc(100%/3 - 60px);
                        background: $white;
                        justify-content: space-between;
                        margin: 0 30px 60px 30px;
                        @media only screen and (max-width: 1200px) {
                            margin: 0 15px 30px 15px;
                            width: calc(100%/3 - 30px);
                        }
                        @media only screen and (max-width: 991px) {
                            margin: 0 0 15px 0;
                            width: 100%;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
            .info_box{
                background: $white;
                padding: 30px 50px;
                color: $header_color;
                @media only screen and (max-width: 1200px) {
                    padding: 30px;
                }
                &.parking{
                    p{
                        background-image: url('/img/visit_icon1.png');
                    }
                }
                &.restaruant{
                    p{
                        background-image: url('/img/visit_icon2.png');
                    }
                }
                &.pets{
                    p{
                        background-image: url('/img/visit_icon3.png');
                    }
                }
                p{
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-size: contain;
                    min-height: 50px;
                    line-height: 50px;
                    font-weight: 600;
                    padding-left: 70px;
                    background-repeat: no-repeat;
                    background-position: left center;
                    @media only screen and (max-width: 991px) {
                        padding-left: 0;
                        padding-top: 70px;
                        background-position: center top;
                        text-align: center;
                    }
                }
            }
        }
        &.tips{
            .tips_boxes{
                > ul{
                    display: flex;
                    flex-flow: row;
                    margin: 0 -30px;
                    flex-wrap: wrap;
                    @media only screen and (max-width: 1200px) {
                        margin: 0 -15px;
                    }
                    @media only screen and (max-width: 991px) {
                        margin: 0;
                        display: block;
                    }
                    > li{
                        width: calc(100%/3 - 60px);
                        background: $white;
                        justify-content: space-between;
                        margin: 0 30px 60px 30px;
                        @media only screen and (max-width: 1200px) {
                            margin: 0 15px 30px 15px;
                            width: calc(100%/3 - 30px);
                        }
                        @media only screen and (max-width: 991px) {
                            margin: 0 0 15px 0;
                            width: 100%;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                        &.tip_box{
                            div{
                                &.icon{
                                    width: 35%;
                                    height: 0;
                                    padding-bottom: 30%;
                                    background-color: $accent_color;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: 50% auto;
                                    @media only screen and (max-width: 991px) {
                                        width: 100%;
                                        background-size: auto 50%;
                                    }
                                }
                                &.desc{
                                    padding: 50px;
                                    @media only screen and (max-width: 991px) {
                                        padding: 15px;
                                        text-align: center;
                                    }
                                    header{
                                        margin-bottom: 30px;
                                        @media only screen and (max-width: 991px) {
                                            margin-bottom: 15px;
                                        }
                                        h3{
                                            font-size: 20px;
                                            line-height: 30px;
                                            color: $header_color;
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .prices{
        border-top: 1px solid #dce0e3;
        padding: 50px 0;
        @media only screen and (max-width: 991px) {
            padding: 0 0 30px 0;
        }
    }
    .important_documents{
        background: $white;
        padding: 50px 0;
        @media only screen and (max-width: 991px) {
            padding: 30px 0;
        }
        .btns{
            > li{
                display: inline-block;
                vertical-align: top;
                margin-right: 70px;
                @media only screen and (max-width: 991px) {
                    margin-right: 0;
                    width: 100%;
                    text-align: center;
                    margin-bottom: 15px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}

.search_window{
    position: fixed;
    height: 100%;
    width: 100%;
    background: $white;
    z-index: 2;
    top: 0;
    left: 0;
    display: none;
    @media only screen and (max-width: 991px) {
        z-index: 12;
        overflow-y: scroll;
        .close_icon{
            position: absolute;
            top: 0;
            right: 0;
            width: 30px;
            height: 0;
            padding-bottom: 30px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url('/img/close_search.png');
        }
    }
    form{
        //display: flex;
        //align-items: center;
        width: 100%;
        left: 0;
        position: absolute;
        bottom: 0;
        > div{
            header{
                color: $accent_color;
                margin: 50px 0;
                text-align: center;
                @media only screen and (max-width: 1500px) {
                    margin-bottom: 30px 0;
                }
                h2{
                    font-size: 60px;
                    line-height: 60px;
                    font-weight: 700;
                    @media only screen and (max-width: 1500px) {
                        font-size: 40px;
                        line-height: 40px;
                    }
                }
            }ul{
                margin-bottom: 50px;
                @media only screen and (max-width: 1500px) {
                    margin-bottom: 30px;
                }
                li{
                    display: inline-block;
                    vertical-align: top;
                    text-align: left;
                    margin-right: 80px;
                    @media only screen and (max-width: 1500px) {
                        margin-right: 0;
                        width: 100%;
                        margin-bottom: 15px;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    > div{
                        display: inline-block;
                        vertical-align: top;
                        &.box{
                            border: 3px solid #d3d3d3;
                            width: 38px;
                            height: 0;
                            padding-bottom: 32px;
                            cursor: pointer;
                            position: relative;
                            @media only screen and (max-width: 991px) {
                                width: 28px;
                                padding-bottom: 22px;
                            }
                            > div{
                                @include fill;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: cover;
                                &.yes{
                                    &.on{
                                        background-image: url('/img/check_icon.png');
                                    }
                                }
                            }
                        }
                        &.default_font{
                            color: #7e7e7e;
                            padding-top: 5px;
                            padding-left: 23px;
                            font-weight: 700;
                            p{
                                font-weight: inherit;
                            }
                        }
                    }

                }
            }
            .search_box{
                ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: $black;
                  }
                  ::-moz-placeholder { /* Firefox 19+ */
                    color: $black;
                  }
                  :-ms-input-placeholder { /* IE 10+ */
                    color: $black;
                  }
                  :-moz-placeholder { /* Firefox 18- */
                    color: $black;
                  }
                input{
                    display: inline-block;
                    vertical-align: top;
                    border: none;
                    border-bottom: 2px solid $accent_color;
                    height: 60px;
                    padding-top: 0;
                    padding-bottom: 0;
                    background-color: transparent;
                    color: $black;
                    &.s_input{
                        width: calc(100% - 90px);
                        font-size: 20px;
                        font-weight: 600;
                        @media only screen and (max-width: 991px) {
                            font-size: 14px;
                        }
                    }
                    &.s_submit{
                        width: 90px;
                        font-size: 16px;
                        font-weight: 600;
                        text-align: left;
                        background-repeat: no-repeat;
                        background-position: right center;
                        background-image: url('/img/search_icon2.png');
                        @media only screen and (max-width: 991px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}

.search_results{
    background: $theme_color1;
    padding-bottom: 50px;
    .section_header{
        h2{
            display: inline-block;
            background: $theme_color1;
            position: relative;
            z-index: 2;
            padding-right: 20px;
        }
    }
    div.news_list{
        ul{
            li.one_news{
                width: 100%;
                margin-right: 0;
                &:last-child{
                    margin-bottom: 0;
                }
                .news_desc{
                    .desc{
                        line-height: 30px;
                    }
                }

                &.archive {

                    article {
                        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                        filter: grayscale(100%);
                        opacity: 0.5;

                    }

                    .archive_desc {
                        background:none;
                    }
                }
            }
        }
    }
    .search_again{
        margin-top: 50px;
        padding: 0 15px;
        cursor: pointer;
       div.default_font{
           padding: 21px 0;
           background: $accent_color;
           text-align: center;
           font-weight: 600;
           color: $black;
           p{
               padding-top: 3px;
               font-weight: inherit;
               display: inline-block;
               padding-right: 50px;
               background-position: right center;
               background-repeat: no-repeat;
               background-image: url('/img/header_icon_w.png');
           }
       }
    }
}

.big_calendar {
    display: block;
    @media only screen and (max-width: 991px) {
        display:none;
    }
}

.small_calendar {
    display: none;
    @media only screen and (max-width: 991px) {
        display:block;
    }
}

.news_list {
    background: $theme_color1;
    padding:35px 0;

    .one_news {
        padding-bottom:60px;

        .image_box {
            background-size:cover;
            background-position:center center;
            background-color:#000;
            width:100%;
            padding-bottom:66%;
        }

        .col-md-12 {

            > .content_box {
                padding-top: 4% !important;
            }
        }

        .content_box {
            font-size:16px;
            padding-top:14%;
            color: $font_color3;

            > div {
                display:inline-block;
                vertical-align: top;

                &.date_box {
                    width:50px;
                    padding-top:5px;

                    //width: 50px;
                    color: #686867;
                    background-repeat: no-repeat;
                    background-image: url(./../img/date_dots.png);
                    background-position: right top;
                    span{
                        width: 100%;
                        font-size: 37px;
                        line-height: 37px;
                        font-weight: 700;
                        color: #3bc8f5;
                        display: block;
                    }

                    .date_no {
                        font-size:32px;
                        color:$special_color;
                        font-weight: bold;
                    }

                    .month_short_name {
                        font-size:20px;
                        color:$font_color3;
                    }
                }

                &.txt_box {
                    width: calc(100% - 50px);
                    padding-left: 36px;
                }
            }
        }
    }
}

.news_details {
    background: $theme_color1;
    padding-top:50px;

    .image_box {
        padding-bottom:50px;
    }

    .title {
        font-size:20px;
        font-weight: bold;
        margin-bottom:30px;
    }
}

.boxes_image_and_title {
    background: $theme_color1;
    padding-top:50px;

    .one_box {
        margin-bottom:35px;

        a {
            text-decoration: none;
        }

        .image_box {
            width:100%;
            padding-bottom:130%;
            position:relative;

            > div {
                position:absolute;
                width:100%;
                height:108%;

                background-size:cover;
                background-position:center center;
                background-color:#000;
            }
        }

        .title_box {
            position:relative;
            z-index:2;
            background-color:#fff;
            width:80%;
            margin: 0 10%;
            border-top:3px solid $accent_color;
            padding:20px 30px;

            text-decoration: none;
            text-align: center;

            .title {
                font-size:22px;
                color:#23201F;
                text-transform: uppercase;
                padding-bottom:10px;
            }

            .autor {
                font-size:14px;
                color:$font_color;
            }
        }
    }

}

.event_info{
    background: $white;
    padding: 25px 40px;
    margin-bottom: 50px;
    @media only screen and (max-width: 1500px) {
        padding: 20px;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 0 !important;
    }
    ul{
        width: 100%;
        @media only screen and (max-width: 991px) {
            display: block;
        }
        li{
            display: inline-block;
            width:50%;
            height:70px;
            vertical-align: top;
            background-position: left center;
            background-repeat: no-repeat;
            padding-right: 60px;
            @media only screen and (max-width: 1500px) {
                padding-right: 30px;
            }
            @media only screen and (max-width: 991px) {
                padding-right: 0;
                height: auto;;
                width: 100%;
                display: block;
                background-position: center top;
                padding-left: 0;
                padding-top: 68px;
                text-align: center;
                &:first-child{
                    padding-bottom: 20px;
                }
            }
            &:last-child{
                margin-right: 0;
            }
            //width: 50%;
            padding-left: 68px;
            padding-top: 27px;
            font-weight: 600;
            &.date{
                background-image: url('/img/date_icon1.png');
            }
            &.place{
                background-image: url('/img/place_icon1.png');
            }
            &.ticket, &.type{
                background-image: url('/img/ticket_icon.png');
            }
            &.info, &.group{
                background-image: url('/img/info_icon.png');
            }
        }
    }
}



.boxes_image_and_desc {
    background: $theme_color1;
    padding-top:50px;

    .one_box {
        margin-bottom:45px;

        a {
            text-decoration: none;
        }

        .image_box {
            width:100%;
            padding-bottom:66%;
            position:relative;
            margin-bottom:25px;

            > div {
                position:absolute;
                width:100%;
                height:100%;

                background-size:cover;
                background-position:center center;
                background-color:#000;
            }
        }

        .title_box {

            .desc {
                font-size: 16px;
                line-height: 30px;
                color: #000;
            }

            .link_box {
                padding-top:20px;
                text-align:right;

                a , p{
                    color:#000;
                    font-size:16px;
                    text-decoration: underline;
                }
            }
        }
    }

}

.exhibition2 {
    padding:50px 0;
    width:100%;
    overflow:hidden;
    background-color:$theme_color1;
    p{
        background: rgba(0, 0, 0, 0.01);
    }
    @media only screen and (max-width: 991px) {
        padding: 50px 0 0 0;
    }
    > div {
        float:left;
        position:relative;
        @media only screen and (max-width: 991px) {
            float: none;
        }
        &.full_size {
            width:100%;
            padding-bottom:31%;

            .image_box {
                width:100%;
                height:100%!important;

                .image_title_box {
                    width: 67%;
                    padding-left: 9%;
                    @media only screen and (max-width: 991px) {
                        padding-left: 0;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }

        &.left {
            width:40%;
            padding-bottom:31%;
            @media only screen and (max-width: 991px) {
                width: 100%;
                padding-bottom: 0;
            }
            .image_box {
                width:150%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                .image_title_box {
                    width: 67%;
                    padding-left: 9%;
                    @media only screen and (max-width: 991px) {
                        padding-left: 0;
                        width: 100%;
                        text-align: center;
                    }
                }
            }

            &.left2 {
                margin-top:-5%;
            }
        }

        &.right {
            width:60%;
            padding-bottom:31%;
            @media only screen and (max-width: 991px) {
                width: 100%;
                padding-bottom: 0%;
                display: flex;
                flex-flow: column;
                > div{
                    align-items: stretch;
                    &.title_box{
                        order: 2;
                    }
                    &.image_box{
                        order: 1;
                    }
                }
            }
            &.right2 {
                margin-top:-5%;
                @media only screen and (max-width: 991px) {
                    margin-top: 0;
                }
            }

            .image_box {
                width:100%;
                top:16%;

                .image {

                    .image_title_box {
                        width: 100%;
                        padding-left: 42%;
                       // background: rgba(0, 0, 0, 0.5);
                        @media only screen and (max-width: 991px) {
                           padding-left: 0;
                           text-align: center;
                        }
                    }

                    .border_div {
                        position:absolute;
                        left:0;
                        bottom:0;
                        width:5px;
                        height:59%;
                        background-color:rgba(255,255,255,0.2);
                    }

                    .left_image {
                        position:absolute;
                        width: 33.33%;
                        height:40%;
                        top:0;
                        left:0;

                        box-sizing: content-box;

                        border-right:5px solid rgba(255,255,255,0.2);
                        border-bottom:5px solid rgba(255,255,255,0.2);

                        overflow: hidden;
                        @media only screen and (max-width: 991px) {
                            display: none;
                        }
                        > div {
                            position:absolute;
                            width:300%;
                            height:250%;
                            top:-47.5%;
                            right:0;

                            background-size:cover;
                            background-position:center center;
                        }

                        > a {
                            position:absolute;
                            top:0;
                            left:0;
                            width:100%;
                            height:100%;
                        }
                    }
                }
            }
        }

        .image_box {
            position:absolute;
            left:0;
            top:0;
            height:84%;
            @media only screen and (max-width: 991px) {
                position: relative;
                height: 0;
                padding-bottom: 55%;
            }
            .image {
                position:absolute;
                left:0;
                top:0;
                width:100%;
                height:100%;

                background-size:cover;
                background-position:center center;

                .image_title_box {

                    position:absolute;
                    top:0;
                    height:100%;
                    @media only screen and (max-width: 991px) {
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: flex;

                        -ms-flex-align: center;
                        -webkit-align-items: center;
                        -webkit-box-align: center;

                        align-items: center;
                    }
                    p {
                        margin: 0;
                        position: absolute;
                        top: 50%;
                        transform: translate(0, -50%);

                        font-size:90px;
                        line-height:90px;
                        color:#fff;
                        font-family:"TT Commons";
                        font-weight:bold;
                        @media only screen and (max-width: 991px) {
                            top: auto;
                            position: relative;
                        }
                        @media only screen and (max-width: 1500px) {
                            font-size: 70px;
                            line-height:70px;
                        }
                        @media only screen and (max-width: 1200px) {
                            font-size: 40px;
                            line-height: 40px;
                        }
                        @media only screen and (max-width: 991px) {
                            font-size: 10vw;
                            line-height: 10vw;
                            transform: none;
                            width: 100%;
                            padding: 0 15px;
                        }
                    }
                }

                > a {
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                }
            }
        }
    }

    .title_box_one_line {
        position:relative;
        width:100%;

        .row {
            height:100px;
        }

        .title_box {
            height:100px;
            width:90%!important;
            padding: 0 5%;
        }
    }

    .title_box {
        position:absolute;
        height: 16%;
        @media only screen and (max-width: 991px) {
            position: relative;
        }
        .border {
            position:absolute;
            top:calc(50% - 1px);
            height:2px;
            background-color:#D9D9D9;
            width:70%;
            left:15%;
            @media only screen and (max-width: 991px) {
                left: 0;
                width: 100%;
            }
        }

        .bg_dotts {
            background-size:contain!important;
        }

        a {
            font-size:18px;
            color:#000;
            background-color:$theme_color1;
            box-sizing: content-box;
            padding:0 10%;

            margin: 0;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            @media only screen and (max-width: 991px) {
                position: relative;
                top: 0;
                transform: none;
            }
        }

        &.title_box_left {
            top:84%;
            left:0;
            width:100%;
            @media only screen and (max-width: 991px) {
               top: 0;
               text-align: center;
               margin: 30px 0;
            }
            a {
                right:5%;
                @media only screen and (max-width: 991px) {
                    right: 0;
                }
            }

            .bg_dotts {
                background: url('/img/left_dotts.png') right center no-repeat;
                position:absolute;
                top:10%;
                right:1.5%;
                width:10%;
                height:80%;
                @media only screen and (max-width: 991px) {
                    display: none;
                }
            }


        }

        &.title_box_right {
            top:0;
            right:0;
            width:66.66%;
            @media only screen and (max-width: 991px) {
                width: 100%;
                text-align: center;
                margin: 30px 0;
            }
            a {
                left:5%;
                @media only screen and (max-width: 991px) {
                    left: 0;
                }
            }

            .bg_dotts {
                background: url('/img/dotts_right.png') left center no-repeat;
                position:absolute;
                top:10%;
                left:1.5%;
                width:10%;
                height:80%;
                @media only screen and (max-width: 991px) {
                    display: none;
                }
            }
        }
    }
}

.boxes_image_and_title_details {
    background-color:$theme_color1;
    padding:50px 0;

    .image_box {
        img {
            width:100%;
            height:auto;
            margin-bottom:30px;
        }
    }

    .buy_info_box {
        background-color:#fff;
        padding:30px 35px 10px 35px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
            padding: 15px;
        }
        .info_box_title {
            padding-bottom:20px;
        }

        .info_box {
            padding-bottom:20px;

            .title {
                color:#494949;
                font-size:18px;
                line-height:28px;
            }

            .desc {
                font-size:18px;
                line-height:28px;
            }
        }
    }

    .title_box {
        border-bottom:1px solid #DFDFDF;
        padding-bottom:30px;
        margin-bottom:35px;
    }
}
.linked_box{
    display: block;
    position: relative;
    height: 100%;
    z-index: 2;
}
.collections_box {
    background-color:$theme_color1;
    padding:50px 0;

    .title_box {
        .row {
            padding-bottom:80px;
            @media only screen and (max-width: 991px) {
                padding-bottom: 30px;
            }
            .link_box {
                a {
                    display:block;
                    width:100%;
                    text-align:center;
                    font-size:20px;
                    color:$black;
                    padding:30px;
                    background-color:#03B6EE;
                    font-weight: 400;
                    @media only screen and (max-width: 1500px) {
                        font-size: 15px;
                        padding: 20px;
                    }
                    @media only screen and (max-width: 991px) {
                        padding: 15px;
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    .list_box {
        width:100%;

        > ul {
            > li {
                width:100%;
                margin-bottom:80px;
                position:relative;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 30px;
                    &:nth-child(even){
                        display: flex;
                        flex-flow: column;
                        > div{
                            &.image_box{
                                order: 1;
                            }
                            &.description{
                                order: 2;
                            }
                        }
                    }
                }
                &:last-of-type {
                    margin-bottom:0;
                }

                > a {
                    @include fill;
                }

                .image_box {
                    vertical-align: top;
                    display:inline-block;
                    width:75%;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                    > div {
                        background-color:#000;
                        width:100%;
                        padding-bottom:43%;
                        position: relative;
                        background-size:cover;
                        background-position:center center;
                    }
                }

                .description {
                    padding-left:50px;
                    vertical-align: top;
                    display:inline-block;
                    width:25%;
                    position:relative;
                    padding-bottom:32.3%;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        padding-left: 0;
                        padding-top: 15px;
                        padding-bottom: 0;
                    }
                    &:first-child {
                        padding-left:0!important;

                        > div {
                            padding-right: 50px;
                        }
                    }

                    > div {
                        margin: 0;
                        position: absolute;
                        top: 50%;
                        transform: translate(0, -50%);
                        @media only screen and (max-width: 991px) {
                            transform: none;
                            position: relative;
                        }
                    }
                }
            }
        }
    }
}

.education_area {
    background-color:$theme_color1;
    padding:50px 0;

    .one_box {
        text-align:center;


        .inner_box {
            height:300px;
            position:relative;
            background-color:#231F20;
            margin-bottom:30px;

            background-size:cover;
            background-position:center center;

            .valign {
                width:100%;
                text-align: center;
                margin: 0;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);

                img {
                    margin-bottom:35px;
                }

                h3 {
                    color:#fff;
                    font-weight:bold;
                    font-size:30px;
                }
            }
        }
    }
}

.main_header {
    width:100%;
    margin: 50px 0;

    .content_box {
        display:table;
        width:100%;
        @media only screen and (max-width: 991px) {
            display: block;
        }
        .left_box {
            display:table-cell;
            width:1px;
            padding-right:20px;
            white-space: nowrap;
            @media only screen and (max-width: 991px) {
                padding-right: 0;
                display: block;
                text-align: center;
                margin-bottom: 30px;
            }
        }

        .right_box {
            display:table-cell;
            text-align: right;
            position:relative;
            @media only screen and (max-width: 991px) {
                display: block;
            }
            &:before {
                position:absolute;
                top:23px;
                left:0;
                content:"";
                width:100%;
                border:1px solid #e7e7e7;
                @media only screen and (max-width: 991px) {
                    display: none;
                }
            }

            ul.filters {
                white-space: nowrap;
                @media only screen and (max-width: 991px) {
                    white-space: normal;
                }
                > li {
                    display:inline-block;
                    margin-left:10px;

                    white-space: normal!important;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        padding-left: 0;
                        margin-bottom: 15px;
                        margin-left: 0;
                        position: relative;
                        &:hover{
                            z-index: 2;
                        }
                        &:last-child{
                            margin-bottom: 0;
                        }
                        select{
                            width: 100%;
                            height: 54px;
                            width: 100%;
                            line-height: 54px;
                            text-align: center;
                            background: $white;
                            padding: 0 15px;
                        }
                    }
                }
            }
        }
    }
}

.open_dates_box {
    text-align: center;
    padding: 37px 0;
    .icon{
        padding-bottom: 54px !important;
    }
    > div {
        > div{
            width: 100%;
            padding-top: 10px;
            &:first-child{
                padding-top: 0;
            }
            > div{
                //width: 50%;
                display: inline-block;
                vertical-align: top;
                &:first-child{
                    text-align: left;
                }
                &:last-child{
                    text-align: right;
                    padding-left: 10px;
                }
            }
        }
        h2{
            text-transform: uppercase;
        }
        .building_desc{
            line-height: 28px;
            h4{
                font-size: 20px;
    line-height: 22px;
            }
        }
    }


}

.page404 {
    text-align:center;
    padding:100px 0 120px 0;

    .code {
        font-size:200px;
        font-weight:bold;
        line-height:210px;
    }

    .info {
        font-size:50px;
    }
}

.main_exhibition > div.container > div.row > div.col-md-6 article {
    padding-bottom: 53.6%;
    @media only screen and (max-width: 991px) {
        padding-bottom: 100%;
    }
}

.movie_btn.active {
    background-image: url('/img/play.png')!important;
}


.big1 {
    zoom: 1.1;
    -moz-transform: scale(1.1);
    -moz-transform-origin: 0 0;
}

.big2 {
    zoom: 1.2;
    -moz-transform: scale(1.2);
    -moz-transform-origin: 0 0;
}

.small1 {
    zoom: 0.9;
    -moz-transform: scale(0.9);
    -moz-transform-origin: 0 0;
}

.small2 {
    zoom: 0.8;
    -moz-transform: scale(0.8);
    -moz-transform-origin: 0 0;
}

.field_checkbox {
    .checkbox_image_box {
        > div {
            display:none;
            border:1px solid #fff;
            width:20px;
            height:20px;
            border-radius:3px;
            position:relative;

            &.on {
                display:block;
            }

            &.yes {
                background-color:$accent_color;
            }
        }
    }
}

.newsletter_check_row {
    padding-top:20px;
    > div{
        display: inline-block;
        vertical-align: top;
        &.check_holder{
            width: 22px;
            > div{
                cursor: pointer;
            }
        }
        &.desc_holder{
            width: calc(100% - 22px);
            padding-left: 15px;
        }
    }
    .title {
        padding-top:2px;
    }
}

.form_error {
    color:red;
    font-size:15px;
    padding-top:10px;
}

.hide_data {
    cursor:pointer;
}

.alt_image {
    @include fill();
    opacity:0;
}

//*{
//    &:focus{
//        outline-color: #3bc8f5;
//        border: 2px solid #3bc8f5;
//        display: block;
//    }
//    a{
//        outline-color: #3bc8f5 !important;
//        //&:focus{
//        //    display: inline-block !important;
//        //}
//    }
//}

.arch_btn{
    cursor: pointer;
}

.skiplinks{
    position: absolute;
    left: 0;
    top: 0;
    ul{
        li{
            a{
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                white-space: nowrap;
                z-index: -1;
                &:focus{
                    opacity: 1;
                    z-index: 9999;
                }
            }
        }
    }
    a{
        background: rgba(0,0,0,0.8);
        color: $white;
        font-size: 16px;
        display: inline-block;
        padding: 5px 15px;
    }
}

.declaration{
    padding: 50px 0;
}

.col-inline{
    > div{
        display: inline-block;
        vertical-align: top;
        float: none;
        @media only screen and (max-width: 991px) {
            width: 100%;
        }
    }
}

button{
    background-color: transparent;
    border: 0;
    padding: 0;
}

*{
    &:focus{
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: 8px !important;
        outline-color:  #3bc8f5 !important;
        position: relative;
        z-index: 9999;
    }
}

.opt{
    button{
        margin-right: 10px;
    }
}

.connected_interviews{
    background: $theme_color1;
}

.interview_details{
    padding: 60px 0 80px 0;
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
        .desc{
            margin-bottom: 30px;
        }
    }
    .interview_top{
        margin-bottom: 65px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
        .image{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            @include bg_pos;
            background-size: cover;
            margin: 0 auto;
        }
        .name{
            padding-top: 15px;
            text-align: center;
            .default_font{
                color: $font_color;
                line-height: 25px;
                strong{
                    color: $header_color;
                    //display: block;
                }
            }
        }
    }
}

.header{
    &.unsubscribing{
        top: 0;
    }
}

.unsubscribing_page{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $black;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    h1{
        color: $white;
        font-size: 62px;
        line-height: 72px;
        @media only screen and (max-width: 991px) {
            font-size: 30px;
            line-height: 40px;
        }
    }
}
